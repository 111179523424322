import React, { useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  FaArrowCircleLeft,
  FaCaretLeft,
  FaChevronLeft,
  FaPlus,
} from "react-icons/fa";
import { jornerProfile } from "../../images";
import { Controller, useForm } from "react-hook-form";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
// import StrengthModal from "./strengthModal";
import StrengthModal from "./SingleModal";
import WhatDoesNotWorksForMeModal from "./SingleModal";
import DisabilityModal from "./disabilityModal";
import WhatWorksForMeModal from "./whatWorksForMeModal";
import GoalsModal from "./DoubleModal";
import MedicationModal from "./medicationModal";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { COUNTRIES } from "../../utils/countries";
import JornerBox from "./jornerBox";
import { ChevronDownIcon } from "@chakra-ui/icons";
import MedicationModal2 from "./medicationModal2";
import ls from "localstorage-slim";
import JornerHeader from "../../components/dashboard/jorner_header";
import {
  Today,
  ValidDate,
  containsNumber,
  containsSpecialChars,
  getYearDifference,
} from "../../utils/utils";
import moment from "moment";
export default function JornerProfileOnboarding({
  currentStep,
  updateCurrentStep,
  updateHaveJorners,
}) {
  const currentPath = window.location.pathname;
  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso,
  }));
  const [jorners, setJorners] = React.useState([]);
  const [jorner, setJorner] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [selectedJorner, setSelectedJorner] = React.useState(0);
  const [disabilitiesConstantsOriginal, setDisabilitiesConstantsOriginal] =
    React.useState({});
  const [strengthConstantsOriginal, setStrengthConstantsOriginal] =
    React.useState({});
  const [whatWorksForMeOriginal, setWhatWorksForMeOriginal] = React.useState(
    {}
  );
  const [whatDoesNotWorksForMeOriginal, setWhatDoesNotWorksForMeOriginal] =
    React.useState({});
  const [goalsOriginal, setGoalsOriginal] = React.useState({});
  const [medicationOriginal, setMedicationOriginal] = React.useState({});
  const [dietaryRestrictionsOriginal, setDietaryRestrictionsOriginal] =
    React.useState({});
  const [allergiesOriginal, setAllergiesOriginal] = React.useState({});

  const [profileStep, setProfileStep] = React.useState(1);
  const [addJorner, setAddJorner] = React.useState(false);
  const [editJorner, setEditJorner] = React.useState(false);
  const [disability, setDisability] = React.useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenStrength,
    onOpen: onOpenStrength,
    onClose: onCloseStrength,
  } = useDisclosure();
  const {
    isOpen: isOpenWhatWorksForMe,
    onOpen: onOpenWhatWorksForMe,
    onClose: onCloseWhatWorksForMe,
  } = useDisclosure();
  const {
    isOpen: isOpenWhatDoesNotWorkForMe,
    onOpen: onOpenWhatDoesNotWorkForMe,
    onClose: onCloseWhatDoesNotWorkForMe,
  } = useDisclosure();
  const {
    isOpen: isOpenGoals,
    onOpen: onOpenGoals,
    onClose: onCloseGoals,
  } = useDisclosure();
  const {
    isOpen: isOpenMedication,
    onOpen: onOpenMedication,
    onClose: onCloseMedication,
  } = useDisclosure();
  const {
    isOpen: isOpenDietaryRestrictions,
    onOpen: onOpenDietaryRestrictions,
    onClose: onCloseDietaryRestrictions,
  } = useDisclosure();
  const {
    isOpen: isOpenAllergies,
    onOpen: onOpenAllergies,
    onClose: onCloseAllergies,
  } = useDisclosure();
  const toast = useToast();
  const [selectedDisability, setSelectedDisability] = React.useState([]);
  const [selectedStrength, setSelectedStrength] = React.useState([]);
  const [selectedWhatWorksForMe, setSelectedWhatWorksForMe] = React.useState(
    []
  );
  const [selectedWhatDoesNotWorksForMe, setSelectedWhatDoesNotWorksForMe] =
    React.useState([]);
  const [selectedGoals, setSelectedGoals] = React.useState([]);
  const [selectedMedication, setSelectedMedication] = React.useState([]);
  const [selectedMedicationArr, setSelectedMedicationArr] = React.useState([]);
  const [selectedDietaryRestrictions, setSelectedDietaryRestrictions] =
    React.useState([]);
  const [selectedAllergies, setSelectedAllergies] = React.useState([]);
  const [initFormValues, setInitFormValues] = React.useState({});

  const [emergencyContact1, setEmergencyContact1] = React.useState("");
  const [emergencyContact2, setEmergencyContact2] = React.useState("");
  const [countryCode1, setCountryCode1] = React.useState("+1");
  const [countryCode2, setCountryCode2] = React.useState("+1");

  // const { value: selectedDisability, getCheckboxProps } = useCheckboxGroup({
  //   defaultValue: [],
  // });

  const fetchAllJorners = async (source = "") => {
    const user = JSON.parse(sessionStorage.getItem("sj_user"));
    var url;
    if (user.tag == "cocg") {
      url = `co_caregiver/${user.id}/jorners`;
    } else {
      url = `jorner`;
    }
    const resp = await httpGetWithTokenRest(url);
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setJorners(resp.data);
      if (source == "") {
        setAddJorner(false);
        updateHaveJorners(resp.data?.length);
        setLoading(false);
      }
    }
  };

  const fetchJornersConstants = async () => {
    const resp = await httpGetWithTokenRest("constants");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setDisabilitiesConstantsOriginal(resp.data.disabilities);
      setStrengthConstantsOriginal([...resp.data.strengths, "Other"]);
      setWhatWorksForMeOriginal(resp.data.what_works_for_me);
      setWhatDoesNotWorksForMeOriginal([...resp.data.what_does_not_work_for_me, "Other"]);
      setGoalsOriginal(resp.data.goals);
      setMedicationOriginal(resp.data.medications);
      setDietaryRestrictionsOriginal([...resp.data.dietary_restrictions, "Other"]);
      setAllergiesOriginal(resp.data.allergies);
    }
  };

  const finishSelection = async (selectedDisabilityArr) => {
    setSelectedDisability(selectedDisabilityArr);
    setValue("disabilities", selectedDisabilityArr);
    if (selectedJorner) {
      await updateJorner({ disabilities: selectedDisabilityArr });
    }
  };

  const finishSelectionStrength = async (arr) => {
    setSelectedStrength(arr);
    setValue("strengths", arr);
    if (selectedJorner) {
      updateJorner({ strengths: arr });
    }
  };
  const updateJorner = async (data) => {
    var response = await httpPutWithTokenRest(
      "caregivers/jorners/" + selectedJorner,
      data
    );
    if (response.error) {
      toast({
        title: "Sorry, we hit a snag!",
        description: `${response.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    } else {
      toast({
        title: "Jorner updated successfully",
        // description: `${response.error}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      return true;
    }
  };
  const finishSelectionWhatWorksForMe = async (arr) => {
    setSelectedWhatWorksForMe(arr);
    setValue("what_works_for_me", arr);
    if (selectedJorner) {
      await updateJorner({ what_works_for_me: arr });
    }
  };

  const finishSelectionWhatDoesNotWorksForMe = async (arr) => {
    setSelectedWhatDoesNotWorksForMe(arr);
    setValue("what_doesnt_work_for_me", arr);
    if (selectedJorner) {
      await updateJorner({ what_doesnt_work_for_me: arr });
    }
  };

  const finishSelectionGoals = async (arr) => {
    setSelectedGoals(arr);
    setValue("goals_am_working_on", arr);
    if (selectedJorner) {
      await updateJorner({ goals_am_working_on: arr });
    }
  };

  // const finishSelectionMedications = (arr, arrObj) => {
  //   setSelectedMedication(arr);
  //   setSelectedMedicationArr(arrObj);
  //   setValue("medications", arr);
  // };
  const finishSelectionMedications = async (arr) => {
    setSelectedMedication(arr);
    setValue("medications", arr);

    var joinedMed = "";
    arr.map((item) => {
      var newmed = item.brand_name + " " + item.generic_name;
      joinedMed = joinedMed == "" ? newmed : joinedMed + ", " + newmed;
    });
    // console.log(joinedMed);
    setSelectedMedicationArr(joinedMed);
    if (selectedJorner) {
      await updateJorner({ medications: arr });
    }
  };

  const finishSelectionDietaryRestrictions = async (arr) => {
    setSelectedDietaryRestrictions(arr);
    setValue("dietary_restrictions", arr);
    if (selectedJorner) {
      await updateJorner({ dietary_restrictions: arr });
    }
  };

  const finishSelectionAllergies = async (arr) => {
    setSelectedAllergies(arr);
    setValue("allergies", arr);
    if (selectedJorner) {
      await updateJorner({ allergies: arr });
    }
  };

  const gotoStep = (index) => {
    if (index == 1) {
      // setValue();
    }
    setProfileStep(index);
  };

  React.useEffect(() => {
    fetchAllJorners();
    fetchJornersConstants();
    register("disabilities");
  }, []);

  useEffect(() => {
    // if (profileStep === 2) {
    //   register("strengths", {
    //     : "This is required",
    //   });
    // }
  }, [profileStep]);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm();

  const editJornerInit = (jorner) => {
    if (jorner.has_disability === true) {
      setDisability("yes");
    } else {
      setDisability("no");
    }
    setValue("last_name", jorner.last_name);
    setValue("dob", jorner.dob);
    setValue("first_name", jorner.first_name);
    setValue("allergies", jorner.allergies);
    setValue("dietary_restrictions", jorner.dietary_restrictions ?? []);
    setValue("goals_am_working_on", jorner.goals_am_working_on ?? []);
    setValue("has_disability", jorner.has_disability ? "yes" : "no");
    setValue("is_co_caregiver", jorner.is_co_caregiver);
    setValue("strengths", jorner.strengths ?? []);
    setValue("type", jorner.type);
    setValue("what_doesnt_work_for_me", jorner.what_doesnt_work_for_me ?? []);
    setValue("what_works_for_me", jorner.what_works_for_me ?? []);
    if (jorner.has_disability) {
      setValue("disabilities", jorner.disabilities ?? []);
    } else {
      setValue("disabilities", []);
    }
    setValue("goals_am_working_on", jorner.goals_am_working_on ?? []);
    setSelectedJorner(jorner.id);
    setJorner(jorner);

    setValue("medications", jorner?.medications ?? []);
    var arr = jorner?.medications;
    // here
    var joinedMed = "";
    if (jorner?.medications) {
      arr.map((item) => {
        var newmed = item.brand_name + " " + item.generic_name;
        joinedMed = joinedMed == "" ? newmed : joinedMed + ", " + newmed;
      });
      setSelectedMedicationArr(joinedMed);
      setSelectedMedication(jorner?.medications);
    } else {
      setSelectedMedicationArr("");
      setSelectedMedication([]);
    }

    setSelectedDisability(jorner.disabilities ?? []);
    if (jorner.strengths) {
      setSelectedStrength(jorner.strengths);
    } else setSelectedStrength([]);
    setSelectedAllergies(jorner?.allergies ?? []);
    setSelectedDietaryRestrictions(jorner.dietary_restrictions ?? []);
    setSelectedDisability(jorner.disabilities ?? []);
    setSelectedGoals(jorner.goals_am_working_on ?? []);
    setSelectedWhatDoesNotWorksForMe(jorner.what_doesnt_work_for_me ?? []);
    setSelectedWhatWorksForMe(jorner.what_works_for_me ?? []);
    try {
      setEmergencyContact1(jorner.contacts[0].replace("+1", ""));
      setEmergencyContact2(jorner.contacts[1].replace("+1", ""));
    } catch (error) {}

    setAddJorner(true);
    setEditJorner(true);
  };

  async function onSubmit(values) {
    if (profileStep === 1) {
      gotoStep(2);
    } else {
      if (values.first_name.length < 2) {
        return toast({
          title: "Joner's name is invalid.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      if (values.last_name.length < 2) {
        return toast({
          title: "Joner's name is invalid.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      // let medications = [];
      // for (let i = 0; i < values.medications.length; i++) {
      //   const element = values.medications[i];
      //   const arrEle = element.split("*");
      //   let x = {
      //     brand_name: arrEle[0],
      //     generic_name: arrEle[1],
      //   };
      //   medications.push(x);
      // }
      let contacts = {
        emergency_contact_one: {
          country_code: countryCode1,
          phone_number: emergencyContact1,
        },
        emergency_contact_two: {
          country_code: countryCode2,
          phone_number: emergencyContact2,
        },
      };
      values.dob = values.dob ? values.dob : jorner.dob
      values.contacts = contacts;
      values.allergies = values.allergies ? values.allergies : [];
      // values.last_name = values.first_name;
      values.is_co_caregiver = false;
      values.medications = selectedMedication;
      // console.log(values);
      // return false;
      let resp;
      if (editJorner) {
        resp = await httpPutWithTokenRest(
          `caregivers/jorners/${selectedJorner}`,
          values
        );
      } else {
        resp = await httpPostWithToken("jorner", values);
      }

      if (resp.error !== null && resp.error !== undefined) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `${resp.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        fetchAllJorners();
        gotoStep(1);
        reset();
        setSelectedAllergies([]);
        setSelectedDietaryRestrictions([]);
        setSelectedDisability([]);
        setSelectedGoals([]);
        setSelectedMedication([]);
        setSelectedJorner(0);
        setSelectedStrength([]);
        setSelectedWhatDoesNotWorksForMe([]);
        setSelectedWhatWorksForMe([]);
      }
    }
  }
  return (
    <>
      <Box px={{ base: 1, md: 8 }} position="relative">
        <Flex gap={2}>
          {!addJorner && (
            <Box left="-20px" position="absolute" mt="8px" cursor="pointer">
              <FaChevronLeft onClick={() => updateCurrentStep(1)} />
            </Box>
          )}
          <Box mb={8}>
            <Flex alignItems={"center"} gap={4}>
              {addJorner && profileStep === 1 && (
                <FaChevronLeft onClick={() => setAddJorner(false)} />
              )}

              <Text fontSize={20} fontWeight={600}>
                {addJorner && selectedJorner
                  ? "Edit Jorner"
                  : "Create Jorners Profile"}
              </Text>
            </Flex>

            <Text fontSize={14} fontWeight={400} color={"#747A8B"}>
              Name of Loved one, age, disability...
            </Text>
          </Box>
        </Flex>
        {addJorner ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex mb={5}>
              <Box w={"45%"} h={2} bg={"success"} borderRadius={8} />
              <Box w={2} />
              <Box
                w={"45%"}
                h={2}
                bg={profileStep > 1 ? "success" : "grey"}
                borderRadius={8}
              />
            </Flex>

            {addJorner && profileStep === 1 && jorner && (
              <Box my={3}>
                <JornerHeader
                  onOpenShare={null}
                  onOpenRemove={null}
                  jorner={jorner}
                  source={"profile"}
                  finishSelection={() => fetchAllJorners("lowkey")}
                />
              </Box>
            )}
            {profileStep === 1 ? (
              <>
                <FormControl isInvalid={errors.first_name}>
                  <FormLabel htmlFor="first_name" fontWeight={600}>
                    Jorner's First Name
                  </FormLabel>
                  <Input
                    id="first_name"
                    placeholder="e.g Simon"
                    {...register("first_name", {
                      required: "This is required",
                    })}
                    borderColor={"#E3E4E8"}
                  />
                  <FormErrorMessage>
                    {errors.first_name && errors.first_name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl  mt={5} isInvalid={errors.last_name}>
                  <FormLabel htmlFor="last_name" fontWeight={600}>
                    Jorner's Last Name
                  </FormLabel>
                  <Input
                    id="last_name"
                    placeholder="e.g Simon"
                    {...register("last_name", {
                      required: "This is required",
                    })}
                    borderColor={"#E3E4E8"}
                  />
                  <FormErrorMessage>
                    {errors.last_name && errors.last_name.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl mt={5} isInvalid={errors.dob}>
                  <FormLabel htmlFor="dob" fontWeight={600}>
                    Date of Birth
                  </FormLabel>
                  <Input
                    id="dob"
                    placeholder="Select date"
                    {...register("dob", {
                      required: "This is required",
                    })}
                    type="date"
                    borderColor={"#E3E4E8"}
                  />
                  <FormErrorMessage>
                    {errors.dob && errors.dob.message}
                  </FormErrorMessage>
                </FormControl>
                {2 == 3 && (
                  <FormControl mt={5} isInvalid={errors.type} fontWeight={600}>
                    <FormLabel htmlFor="type">Select Jorner Profile</FormLabel>
                    <Controller
                      name="type"
                      id="type"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          onChange={onChange}
                          value={value}
                          aria-required
                        >
                          <Stack
                            direction={{ base: "column", md: "row" }}
                            columnGap={2}
                          >
                            <Box
                              border={"1px solid #EBECEF"}
                              borderRadius={8}
                              p={4}
                              flex={1}
                            >
                              <Radio
                                color={"#EBECEF"}
                                colorScheme="red"
                                isRequired
                                value="child"
                              >
                                Child
                              </Radio>
                            </Box>
                            <Box
                              border={"1px solid #EBECEF"}
                              borderRadius={8}
                              p={4}
                              flex={1}
                            >
                              <Radio
                                color={"#EBECEF"}
                                colorScheme="red"
                                isRequired
                                value="adult"
                              >
                                Adult
                              </Radio>
                            </Box>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                    <FormErrorMessage>
                      {errors.type && errors.type.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
                <FormControl
                  mt={5}
                  isInvalid={errors.has_disability}
                  fontWeight={600}
                >
                  <FormLabel htmlFor="has_disability" fontWeight={600}>
                    Does Jorner have a Disability
                  </FormLabel>
                  <Controller
                    name="has_disability"
                    id="has_disability"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        onChange={(e) => {
                          onChange(e);
                          setDisability(e);
                        }}
                        value={value}
                        aria-required
                      >
                        <Stack
                          direction={{ base: "column", md: "row" }}
                          columnGap={2}
                        >
                          <Box
                            border={"1px solid #EBECEF"}
                            borderRadius={8}
                            p={4}
                            flex={1}
                          >
                            <Radio
                              color={"#EBECEF"}
                              colorScheme="red"
                              isRequired
                              value="yes"
                            >
                              Yes
                            </Radio>
                          </Box>
                          <Box
                            border={"1px solid #EBECEF"}
                            borderRadius={8}
                            p={4}
                            flex={1}
                          >
                            <Radio
                              color={"#EBECEF"}
                              colorScheme="red"
                              isRequired
                              value="no"
                            >
                              No
                            </Radio>
                          </Box>
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                  <FormErrorMessage>
                    {errors.age && errors.age.message}
                  </FormErrorMessage>
                </FormControl>

                {disability === "yes" ? (
                  <FormControl
                    mt={5}
                    isInvalid={errors.disabilities}
                    fontWeight={600}
                    onClick={() => onOpen()}
                  >
                    <FormLabel htmlFor="disabilityType">
                      Type of Disability
                    </FormLabel>
                    <Input
                      id="disabilities"
                      name="disabilities"
                      placeholder={
                        selectedDisability.length === 0
                          ? "Select option"
                          : selectedDisability.join()
                      }
                      isReadOnly
                      onClick={() => onOpen()}
                    />
                    <FormErrorMessage>
                      {errors.disabilities && errors.disabilities.message}
                    </FormErrorMessage>
                  </FormControl>
                ) : null}

                <WhatWorksForMeModal
                  isOpen={isOpen}
                  onClose={onClose}
                  whatWorksForMeOriginal={disabilitiesConstantsOriginal}
                  onFinished={finishSelection}
                  title="Disabilities"
                  showOthers={true}
                />
              </>
            ) : null}

            {profileStep === 2 ? (
              <>
                <StrengthModal
                  isOpen={isOpenStrength}
                  onClose={onCloseStrength}
                  wDWFMConstantsOriginal={strengthConstantsOriginal}
                  title="Strength"
                  showOthers={true}
                  onFinished={finishSelectionStrength}
                  defaultV={selectedStrength}
                />
                <WhatWorksForMeModal
                  isOpen={isOpenWhatWorksForMe}
                  onClose={onCloseWhatWorksForMe}
                  whatWorksForMeOriginal={whatWorksForMeOriginal}
                  onFinished={finishSelectionWhatWorksForMe}
                  defaultV={selectedWhatWorksForMe}
                  showOthers={true}
                />

                <WhatDoesNotWorksForMeModal
                  isOpen={isOpenWhatDoesNotWorkForMe}
                  onClose={onCloseWhatDoesNotWorkForMe}
                  wDWFMConstantsOriginal={whatDoesNotWorksForMeOriginal}
                  onFinished={finishSelectionWhatDoesNotWorksForMe}
                  title="What Does Not Work For Me"
                  showOthers={true}
                  defaultV={selectedWhatDoesNotWorksForMe}
                />
               

                <GoalsModal
                  isOpen={isOpenGoals}
                  onClose={onCloseGoals}
                  whatWorksForMeOriginal={goalsOriginal}
                  onFinished={finishSelectionGoals}
                  title="Goals I am working on"
                  defaultV={selectedGoals}
                  showOthers={true}
                />

                <MedicationModal2
                  isOpen={isOpenMedication}
                  onClose={onCloseMedication}
                  medicationConstantsOriginal={medicationOriginal}
                  onFinished={finishSelectionMedications}
                  selected={selectedMedication}
                />

                {/* //This is for Dietary */}
                <WhatDoesNotWorksForMeModal
                  isOpen={isOpenDietaryRestrictions}
                  onClose={onCloseDietaryRestrictions}
                  wDWFMConstantsOriginal={dietaryRestrictionsOriginal}
                  onFinished={finishSelectionDietaryRestrictions}
                  title="Dietary Restrictions"
                  defaultV={selectedDietaryRestrictions}
                  showOthers={true}
                />

                {/* This is for allergies */}
                <WhatWorksForMeModal
                  isOpen={isOpenAllergies}
                  onClose={onCloseAllergies}
                  whatWorksForMeOriginal={allergiesOriginal}
                  onFinished={finishSelectionAllergies}
                  title="Allergies"
                  defaultV={selectedAllergies}
                  showOthers={true}
                  
                />

                <FaArrowCircleLeft onClick={() => gotoStep(1)} />
                <Box>
                  <FormControl
                    isRequired={false}
                    mt={5}
                    isInvalid={errors.strengths}
                    fontWeight={400}
                  >
                    <FormLabel
                      fontSize={14}
                      fontWeight={600}
                      htmlFor="strengths"
                    >
                      Strengths
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="strengths"
                        name="strengths"
                        placeholder={
                          selectedStrength.length === 0
                            ? "Select option"
                            : selectedStrength.join()
                        }
                        isReadOnly
                        onClick={onOpenStrength}
                      />
                      <InputRightElement>
                        <ChevronDownIcon color="gray.500" />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <FormControl
                    isRequired={false}
                    mt={5}
                    isInvalid={errors.what_works_for_me}
                    fontWeight={400}
                  >
                    <FormLabel
                      fontSize={14}
                      fontWeight={600}
                      htmlFor="what_works_for_me"
                    >
                      What works for me
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="what_works_for_me"
                        name="what_works_for_me"
                        placeholder={
                          selectedWhatWorksForMe.length === 0
                            ? "Select option"
                            : selectedWhatWorksForMe.join()
                        }
                        isReadOnly
                        onClick={onOpenWhatWorksForMe}
                      />
                      <InputRightElement>
                        <ChevronDownIcon color="gray.500" />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <FormControl
                    isRequired={false}
                    mt={5}
                    isInvalid={errors.what_doesnt_work_for_me}
                    fontWeight={400}
                  >
                    <FormLabel
                      fontSize={14}
                      fontWeight={600}
                      htmlFor="what_doesnt_work_for_me"
                    >
                      What does not work for me
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="what_doesnt_work_for_me"
                        name="what_doesnt_work_for_me"
                        placeholder={
                          selectedWhatDoesNotWorksForMe.length === 0
                            ? "Select option"
                            : selectedWhatDoesNotWorksForMe.join()
                        }
                        isReadOnly
                        onClick={onOpenWhatDoesNotWorkForMe}
                      />
                      <InputRightElement>
                        <ChevronDownIcon color="gray.500" />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <FormControl
                    isRequired={false}
                    mt={5}
                    isInvalid={errors.goals_am_working_on}
                    fontWeight={400}
                  >
                    <FormLabel
                      fontSize={14}
                      fontWeight={600}
                      htmlFor="goals_am_working_on"
                    >
                      Goals i am working on
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="goals_am_working_on"
                        name="goals_am_working_on"
                        placeholder={
                          selectedGoals.length === 0
                            ? "Select option"
                            : selectedGoals.join()
                        }
                        isReadOnly
                        onClick={onOpenGoals}
                      />
                      <InputRightElement>
                        <ChevronDownIcon color="gray.500" />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <FormControl
                    isRequired={false}
                    mt={5}
                    isInvalid={errors.medications}
                    fontWeight={400}
                  >
                    <FormLabel
                      fontSize={14}
                      fontWeight={600}
                      htmlFor="medications"
                    >
                      Medication
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="medications"
                        name="medications"
                        placeholder={
                          !selectedMedication || selectedMedication.length === 0
                            ? "Select option"
                            : selectedMedicationArr
                        }
                        isReadOnly
                        onClick={onOpenMedication}
                      />
                      <InputRightElement>
                        <ChevronDownIcon color="gray.500" />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <FormControl
                    isRequired={false}
                    mt={5}
                    isInvalid={errors.dietary_restrictions}
                    fontWeight={400}
                  >
                    <FormLabel
                      fontSize={14}
                      fontWeight={600}
                      htmlFor="dietary_restrictions"
                    >
                      Dietary Restrictions
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="dietary_restrictions"
                        name="dietary_restrictions"
                        placeholder={
                          selectedDietaryRestrictions.length === 0
                            ? "Select option"
                            : selectedDietaryRestrictions.join()
                        }
                        isReadOnly
                        onClick={onOpenDietaryRestrictions}
                      />
                      <InputRightElement>
                        <ChevronDownIcon color="gray.500" />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <FormControl
                    isRequired={false}
                    mt={5}
                    isInvalid={errors.allergies}
                    fontWeight={400}
                  >
                    <FormLabel
                      fontSize={14}
                      fontWeight={600}
                      htmlFor="Allergies"
                    >
                      Allergies
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="Allergies"
                        name="Allergies"
                        placeholder={
                          selectedAllergies.length === 0
                            ? "Select option"
                            : selectedAllergies.join()
                        }
                        isReadOnly
                        onClick={onOpenAllergies}
                      />
                      <InputRightElement>
                        <ChevronDownIcon color="gray.500" />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <FormControl mt={5} isInvalid={errors.phone} fontWeight={600}>
                    <FormLabel fontSize={14} fontWeight={600} htmlFor="phone1">
                      Emergency Contact 1
                    </FormLabel>
                    <PhoneNumberInput
                      key={"phoneInput1"}
                      id={"phone1"}
                      country={""}
                      value={emergencyContact1}
                      options={countryOptions}
                      placeholder="Enter phone number"
                      onChange={(value) => setEmergencyContact1(value)}
                      onChangeCode={(value) => setCountryCode1(value)}
                    />
                    <FormErrorMessage>
                      {errors.phone && errors.phone.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl mt={5} isInvalid={errors.phone} fontWeight={600}>
                    <FormLabel fontSize={14} fontWeight={600} htmlFor="phone2">
                      Emergency Contact 2
                    </FormLabel>
                    <PhoneNumberInput
                      country={""}
                      key={"phoneInput2"}
                      id={"phone2"}
                      value={emergencyContact2}
                      options={countryOptions}
                      placeholder="Enter phone number"
                      onChange={(value) => setEmergencyContact2(value)}
                      onChangeCode={(value) => setCountryCode2(value)}
                    />
                    <FormErrorMessage>
                      {errors.phone && errors.phone.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl mt={5} isInvalid={errors.care_level}>
                    <Checkbox name="care_level">
                      <Text fontWeight={400} fontSize={14}>
                        Give this jorner care level access
                      </Text>
                    </Checkbox>

                    <FormErrorMessage>
                      {errors.care_level && errors.care_level.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </>
            ) : null}
            {profileStep === 1 ? (
              <Button
                mt={8}
                mb={4}
                size={"lg"}
                onClick={async () => {
                  const fname = getValues("first_name");
                  const lname = getValues("last_name");
                  const has_disability = getValues("has_disability");
                  const dob = getValues("dob");
                  let type = "child";

                  if (has_disability == "yes") {
                    if (selectedDisability.length == 0) {
                      return toast({
                        title: `Please select disabilities from the list`,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                    }
                  }
                  if (fname === "" || has_disability === "" || dob == "") {
                    return toast({
                      title: "Sorry, we hit a snag!",
                      description: `All fields are required`,
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                  } else {
                    // if the valid date function return true, it means, the date is in future, so its invalid in this case

                    // calclulate age
                    if (ValidDate(dob)) {
                      return toast({
                        title: "Sorry, we hit a snag!",
                        description: `Invalid date selected`,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                    }
                    let today = Today();
                    let selected = moment(dob).format("YYYY-MM-DD");
                    if (today == selected) {
                      return toast({
                        title: "Sorry, we hit a snag!",
                        description: `Jorner DOB cannot be today`,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                    }
                    if (containsSpecialChars(fname) || containsNumber(fname)) {
                      return toast({
                        title: "Joner's fullname is invalid.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                    }
                    const jornerdob = new Date(dob);
                    const y = getYearDifference(jornerdob);
                    if (y > 18) {
                      type = "adult";
                    }
                    if (jorner) {
                      if (fname.length < 2) {
                        return toast({
                          title: "Joner's first name is invalid.",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                      }
                      if (lname.length < 2) {
                        return toast({
                          title: "Joner's last name is invalid.",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                      }
                      await updateJorner({
                        first_name: fname,
                        last_name: lname,
                        type: type,
                        has_disability: has_disability,
                        disabilities: selectedDisability,
                        dob : dob
                      });

                      gotoStep(2);
                    } else {
                      if (fname.length < 2) {
                        return toast({
                          title: "Joner's first name is invalid.",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                      }
                      if (lname.length < 2) {
                        return toast({
                          title: "Joner's last name is invalid.",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                      }
                      const resp = await httpPostWithToken("jorner", {
                        first_name: fname,
                        last_name: lname,
                        type: type,
                        has_disability: has_disability,
                        disabilities: selectedDisability,
                        dob : dob
                      });
                      // console.log(resp);
                      if (resp.error) {
                        toast({
                          title: "Sorry, we hit a snag!",
                          status: "error",
                          description: resp.message ?? resp.error,
                          duration: 500,
                          isClosable: true,
                        });
                      } else {
                        // console.log(resp);
                        toast({
                          title: "Jorner created successfully",
                          status: "success",
                          duration: 500,
                          isClosable: true,
                        });
                        // setSelectedJorner(resp.data.id);
                        // setJorner(resp.data);
                        // setEditJorner(true);
                        gotoStep(2);
                        editJornerInit(resp.data);
                      }
                    }
                    fetchAllJorners("lowkey");
                  }
                }}
                w={"100%"}
                color="#fff"
                borderRadius={64}
                background={"primary"}
                type="button"
              >
                Next
              </Button>
            ) : (
              <Button
                mt={8}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#fff"
                borderRadius={64}
                background={"primary"}
                isLoading={isSubmitting}
                type="submit"
              >
                Next
              </Button>
            )}
          </form>
        ) : (
          <Box>
            {jorners.length > 0 ? (
              jorners.map((e, i) => (
                <JornerBox
                  key={i}
                  jorner={e}
                  refetchAllJorners={fetchAllJorners}
                  editJornerInit={editJornerInit}
                />
              ))
            ) : (
              <Box>
                {loading ? (
                  <Stack mt={5}>
                    <Skeleton startColor="#808080" height="50px" />
                    <Skeleton startColor="#808080" height="50px" />
                    <Skeleton startColor="#808080" height="50px" />
                  </Stack>
                ) : (
                  <>
                    <Center>
                      <Image src={jornerProfile} />
                    </Center>
                    <Text textAlign={"center"} fontSize={16} fontWeight={600}>
                      Create Jorners Profile
                    </Text>
                    <Text
                      textAlign={"center"}
                      fontSize={14}
                      fontWeight={400}
                      color={"paragraph"}
                      mt={3}
                    >
                      A jorner profile serves as a repository for your
                      dependent's info and <br /> can be shared on the go.
                    </Text>
                  </>
                )}
              </Box>
            )}
            <Center
              mt={4}
              color={"secondary"}
              fontSize={16}
              fontWeight={600}
              textAlign={"center"}
              cursor={"pointer"}
              onClick={() => {
                setSelectedJorner(null);
                setAddJorner(true);
                gotoStep(1);
                setValue("dob", "");
                reset();
                setSelectedAllergies([]);
                setSelectedDietaryRestrictions([]);
                setSelectedDisability([]);
                setSelectedGoals([]);
                setSelectedMedication([]);
                setSelectedJorner(0);
                setSelectedStrength([]);
                setSelectedWhatDoesNotWorksForMe([]);
                setSelectedWhatWorksForMe([]);
                setJorner(null);
              }}
            >
              <FaPlus />
              <Box w={1} />
              <Text> Add Jorner</Text>
            </Center>
            {currentPath.includes("onboarding") && (
              <Button
                mt={4}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#fff"
                borderRadius={64}
                background={"primary"}
                onClick={() => updateCurrentStep(3)}
              >
                Skip
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}
