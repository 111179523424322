import React, { useContext, useState } from 'react'
import { Box, Image, Link, Heading, Text, Flex, Spacer, Icon, Button, useToast } from '@chakra-ui/react'
import AppLink from '../link'
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import { notFound, notask} from '../../images';
import { httpPostWithToken } from '../../utils/http_utils';
import { AppContext } from '../../redux/appContext';

function PlanExpired() {
  const {subscription} = useContext(AppContext)
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  
  const isCoCaregiver = user.tag !== "cg" && user.caregiver_type != "primary caregiver";
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const getPaymentLink =async (id, name) => {
    setIsLoading(true);

    var j = await httpPostWithToken("subscription",{
        "plan_id" : id,
        "type" : subscription.sub_type ,
        "callback_url" : 'http://'+window.location.host+"/validate-payment"
    });
    if (j.status == "true" || j.status == true) {
        window.location.href = j.data.payload.url
        return;
    }
    setIsLoading(false);

    return toast({
        title: "Sorry, we hit a snag!",
        description: `${j.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
}
  return (
   
        <Box mt="40px" shadow={"sm"} background="white" padding="30px" borderRadius={"16px"} display="flex" flexDirection="column" justifyContent={"center"} alignItems="center">
          <Image src={notFound} boxSize={"205px"} ></Image>
          <Box h="10px" />
          <Heading as="h6" textAlign={"center"} fontSize={"20px"}>Your Subscription has Expired</Heading>
          <Box h="10px" />

          <Box textAlign={"center"} maxW="480px">
          <Text color="paragraph">
          To continue enjoying our services, please renew your subscription.
          </Text>
          <Box h="10px" />
          {
            !isCoCaregiver 
            &&
          <Flex alignItems={"center"} gap="3" justifyContent={"center"}>
            {
            subscription.plan_name.toLowerCase() != "starter" && <Button onClick={()=>getPaymentLink(subscription.plan_id)} isLoading={isLoading} colorScheme='purple'>Renew</Button>
            }
            <AppLink href='/plans' text='See Plans' color='primary' padding='0' height='fit-content' background='transparent' fontWeight='500' shadow='none' />
          </Flex>
          }

          </Box>
        </Box>

  )
}

export default PlanExpired