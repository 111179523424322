import { Box, Flex, Heading, Image, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import AppLink from "../../components/link";
import ls from "localstorage-slim";
import { homeIcon1, homeIcon2, homeIcon3, Logo } from "../../images";

function Home() {
  // const token = sessionStorage.getItem("sj_token");
  sessionStorage.clear();
  ls.clear();
  // if (token) {
  //   return <Navigate to={dashboardRoute} />;
  // }

  return (
    <Flex background={"black"} min-height={"100vh"}>
      <Box
        flex="1"
        zIndex={1}
        borderTopRadius={{ base: "20px", md: 0 }}
        width={{ base: "90%", md: "auto" }}
        background={"primary"}
        position={{ base: "fixed", md: "static" }}
        display={"flex"}
        justifyContent={"center"}
        alignItems="center"
        h={"100vh"}
        m={{ base: "10px 5% 0", md: "0" }}
      >
        <Image src={Logo} height="55px" objectFit={"contain"} />
      </Box>
      <Box
        zIndex={2}
        flex="1"
        background={"white"}
        borderTopRadius={{ base: "20px", md: 0 }}
        paddingTop={{ base: "40px", md: 0 }}
        display="flex"
        h={{ base: "calc(100vh - 30px)", md: "100vh" }}
        flexDirection={{ base: "column", md: "row" }}
        alignItems={"center"}
        justifyContent={"center"}
        align-items="center"
        m={{ base: "30px 0 0 0", md: "0" }}
      >
        <Box maxWidth={"420px"} width="95%">
          <Heading
            display={"flex"}
            justifyContent="center"
            flexWrap="nowrap"
            as={"h3"}
            fontSize="28px"
            color={"black"}
          >
            Welcome to&nbsp;<Text color={"secondary"}> Sojorne</Text>
          </Heading>
          <Box w="100%" mt={"25px"}>
            <Flex gap={3} mb="20px" alignItems="start">
              <Image
                boxSize={"45px"}
                objectFit="contain"
                src={homeIcon1}
              ></Image>
              <Box>
                <Heading fontSize={"18px"}>
                  Get Organized with Events and Tasks
                </Heading>
                <Text mt={1} color={"paragraph"} fontSize="14px">
                  Become more productive, focused, organized.
                </Text>
              </Box>
            </Flex>
            <Flex gap={3} mb="20px" alignItems="start">
              <Image
                boxSize={"45px"}
                objectFit="contain"
                src={homeIcon2}
              ></Image>
              <Box>
                <Heading fontSize={"18px"}>Find Providers</Heading>
                <Text mt={1} color={"paragraph"} fontSize="14px">
                  Get access to vetted service providers for your dependents
                  period.
                </Text>
              </Box>
            </Flex>
            <Flex gap={3} alignItems="start">
              <Image
                boxSize={"45px"}
                objectFit="contain"
                src={homeIcon3}
              ></Image>
              <Box>
                <Heading fontSize={"18px"}>Join Community</Heading>
                <Text mt={1} color={"paragraph"} fontSize="14px">
                  Find and connect with your tribe to leverage shared
                  experiences you have.
                </Text>
              </Box>
            </Flex>
            <Box mt={"35px"} display={{ base: "none", md: "block" }}>
              <AppLink />
            </Box>
          </Box>
        </Box>
        <Spacer display={{ base: "block", md: "none" }} />
        <Box
          mt={"35px"}
          mb="20px"
          w="95%"
          display={{ base: "block", md: "none" }}
        >
          <AppLink />
        </Box>
      </Box>
    </Flex>
  );
}

export default Home;
