import React, { useState } from "react";
import DashboardLayout from "../../layout/dashboard";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Tag,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  FormHelperText,
  useToast,
  Center,
  Image,
  Container,
  Stack,
  Skeleton,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import { FaCalendar, FaPlus } from "react-icons/fa";
import { BsCalendarWeek } from "react-icons/bs";
import { SearchIcon } from "@chakra-ui/icons";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import timegrid from "@fullcalendar/timegrid";
import {
  circlePattern,
  communityImg2,
  locationImg,
  successGif,
} from "../../images";
import { useForm } from "react-hook-form";
import { EmailChipInput } from "../../components/emailInput";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  httpGetWithTokenRest,
  httpPatch2WithTokenRest,
  httpPostWithToken,
} from "../../utils/http_utils";
import CustomModal from "../../components/customModal";
import moment from "moment";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import ls from "localstorage-slim";
import { getClosestEvent, isValidUrl, sortArrayByKey } from "../../utils/utils";
import { DatePicker } from "rsuite";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);
export default function Calendar() {
  const userPermission = ls.get("sj_cocg_permission", { decrypt: true });
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const [isPicker, setIsPicker] = useState(false);
  const [displayedDate, setDisplayedDate] = useState("");
  const isCoCaregiver = user.tag !== "cg" && user.caregiver_type != "primary caregiver";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenSuccess,
    onOpen: onOpenSuccess,
    onClose: onCloseSuccess,
  } = useDisclosure();

  const {
    isOpen: isOpenView,
    onOpen: onOpenView,
    onClose: onCloseView,
  } = useDisclosure();
  const [viewCalendar, setViewCalendar] = React.useState(false);
  const [plusClicked, setPlusClicked] = React.useState(true);
  const [edit, setEdit] = React.useState(false);
  const [noSearchResult, setNoSearchResult] = React.useState(false);
  
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [myEvents, setMyEvents] = React.useState([]);
  const [upcomingEvent, setUpcomingEvent] = React.useState(null);
  const [myEventsFormatted, setMyEventsFormatted] = React.useState([]);
  const [myEventsGroupedOriginal, setMyEventsGroupedOriginal] = React.useState(
    []
  );
  const [myEventsGrouped, setMyEventsGrouped] = React.useState([]);
  const [currentEvent, setCurrentEvent] = React.useState({});
  const toast = useToast();
  const [loading, setLoading] = React.useState(true);

  const [date, setDate] = React.useState("");
  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  async function onSubmit(values) {
    if(!plusClicked) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `Use "+" or "," or "Enter" key to save event guest`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    if (date === "") {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `Please select date and time`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    values.date = moment(date).format("YYYY-MM-D H:mm:ss");
    values.start_time = moment(date).format("YYYY-MM-D H:mm:ss");
    values.end_time = moment(date).format("YYYY-MM-D H:mm:ss");
   
    let now = moment();
    let cal = moment(date)
    if(now > cal){
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `You cannot create Event with date in the past`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    let resp;
    if (edit) {
      delete values["longitude"];
      delete values["latitude"];
      delete values["eventId"];
      delete values["id"];
      delete values["guest_counts"];
      console.log(values);
      resp = await httpPatch2WithTokenRest(
        `events/${selectedEvent.eventId}`,
        values
      );
    } else {
      delete values["id"];
      delete values["eventId"];
      delete values["guest_counts"];
      
      values["longitude"] = 0;
      values["latitude"] = 0;
      resp = await httpPostWithToken("events", values);
    }

    if (2 === 3) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      onClose();
      onOpenSuccess();
      setCurrentEvent(values);
      reset();
      fetchAllMyEvents();
      fetchAllEvents();
    }
  }

  const fetchAllMyEvents = async () => {
    const resp = await httpGetWithTokenRest("events/me");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      const data = resp.data;
      
      const groups = data.reduce((groups, event) => {
        const date = event.date.split("T")[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        //console.log(event)
        groups[date].push(event);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          events: groups[date],
        };
      });
      let formattedEvent = [];
      for (let i = 0; i < data.length; i++) {
        const e = data[i];
        let d = {
          title: e?.name,
          start: e?.date,
          eventId: e?.id,
          ...e,
        };
        formattedEvent.push(d);
      }
      let allEvents = groupArrays;
      allEvents = sortArrayByKey(allEvents)
      setMyEventsGrouped(allEvents);
      setMyEventsGroupedOriginal(allEvents);
      setMyEvents(resp.data);
      setMyEventsFormatted(formattedEvent);
      setEdit(false);
      setLoading(false);
    }
  };

  const fetchAllEvents = async () => {
    const resp = await httpGetWithTokenRest("events");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      const data = resp.data;
      console.log(data);

      setUpcomingEvent(getClosestEvent(data));
    }
  };

  const handleSearch = (keyword) => {
    if (keyword.length > 0) {
      setNoSearchResult(false)
      const filter = myEvents.filter((e) =>
        e?.name?.toLowerCase().includes(keyword.toLowerCase())
      );
      const groups = filter.reduce((groups, event) => {
        const date = event.date.split("T")[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(event);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          events: groups[date],
        };
      });
      let formattedEvent = [];
      for (let i = 0; i < filter.length; i++) {
        const e = filter[i];
        let d = {
          title: e?.name,
          start: e?.date,
          eventId: e?.id,
          ...e,
        };
        formattedEvent.push(d);
      }
      let allEvents = groupArrays;
      allEvents = sortArrayByKey(allEvents)
      if(allEvents.length > 1) {
        setNoSearchResult(false)
      }else {
        setNoSearchResult(true)
      }
      setMyEventsGrouped(allEvents);
    } else {
      setMyEventsGrouped(myEventsGroupedOriginal);
    }
  };

  const handleViewEvent = (e) => {
    setSelectedEvent({ ...e, eventId: e?.id });
    onOpenView();
  };

  const handleEditEvent = () => {
    reset({
      ...selectedEvent,
    });
    setDate(selectedEvent.date.replace("T", " ").replace("Z", ""));
    setDisplayedDate(
      moment(selectedEvent.date.replace("T", " ").replace("Z", "")).format("MMMM Do YYYY, h:mm:ss a")
    );
    setEdit(true);
    onCloseView();
    onOpen();
  };

  React.useEffect(() => {
    register("guests");
    fetchAllMyEvents();
    fetchAllEvents();
  }, []);

  return (
    <DashboardLayout
      element={
        <Box p={5} bg={"#FAF8FF"}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text fontSize={24} fontWeight={600}>
              Calendar
            </Text>
            <Box w={"160px"}>
              {!isCoCaregiver ? (
                <Button
                  mb={2}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={100}
                  background={"primary"}
                  fontSize={14}
                  fontWeight={500}
                  onClick={() => {
                    reset();
                    setValue("name", "");
                    setValue("descriptions", "");
                    setValue("guests", []);
                    setEdit(false);
                    setDisplayedDate("");
                    setSelectedEvent(null);
                    setDate("");
                    onOpen();
                  }}
                >
                  <Text as={"span"} mr={1}>
                    <FaPlus />
                  </Text>{" "}
                  Create Event
                </Button>
              ) : userPermission !== null &&
                userPermission.includes("calendar:create") ? (
                <Button
                  mb={2}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={100}
                  background={"primary"}
                  fontSize={14}
                  fontWeight={500}
                  onClick={() => {
                    reset();
                    setValue("name", "");
                    setValue("guests", []);
                    setValue("descriptions", "");
                    setEdit(false);
                    setDisplayedDate("");
                    setSelectedEvent(null);
                    setDate("");
                    onOpen();
                  }}
                >
                  <Text as={"span"} mr={1}>
                    <FaPlus />
                  </Text>{" "}
                  Create Event
                </Button>
              ) : null}
            </Box>
          </Box>
          <Box bg={"#fff"} borderRadius={12} p={3}>
            <Flex flexDir={{base : "column", md : "row"}} alignItems={{base : "start", md : "center"}} mb={5}>
              <Text fontSize={16} fontWeight={500}>
                Upcoming Events
              </Text>
              <Spacer />
              <Flex justifyContent={"space-between"} >
              <Tag
                border={"1px solid #EBECEF"}
                borderRadius={"100%"}
                onClick={() => setViewCalendar(!viewCalendar)}
              >
                <BsCalendarWeek color="#747A8B" />
              </Tag>
              <Box w={5} />
              <InputGroup bg={"#fff"} w={{base : "250px", md : "300px"}}>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  fontSize={12}
                  fontWeight={400}
                  id="keyword"
                  minW={"100px"}
                  borderRadius={100}
                  placeholder="Search"
                  borderColor={"#E3E4E8"}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </InputGroup>
              </Flex>
            </Flex>
            {loading && (
              <Stack mt={5}>
                <Skeleton startColor="#808080" height="50px" />
                <Skeleton startColor="#808080" height="50px" />
                <Skeleton startColor="#808080" height="50px" />
              </Stack>
            )}
            {viewCalendar ? (
              <Box display={"flex"} flexDir={{base : "column", lg : "row"}}  gap={3}>
                <Box w={{base : "100%", lg : "60%"}}>
                  <FullCalendar
                    plugins={[dayGridPlugin, timegrid]}
                    initialView="timeGridDay"
                    weekends={true}
                    events={myEventsFormatted}
                    eventContent={renderEventContent}
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    eventClick={(e) =>
                      handleViewEvent(e.event._def.extendedProps)
                    }
                  />
                </Box>
                <Box w={{base : "100%", lg : "40%"}} minH={"100"} bg={"#fff"}>
                  <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    weekends={false}
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth",
                    }}
                    selectable={true}
                  />
                </Box>
              </Box>
            ) : (
              <Box id="calendarId" mt={3}>
                {(!loading && myEvents.length < 1)  && (
                  <Container
                    maxW="md"
                    width={{ base: "auto", md: "100%" }}
                    textAlign={"center"}
                  >
                    <Center>
                      <Image src={communityImg2} />
                    </Center>
                    <Text mb={2} fontSize={18} fontWeight={600}>
                      No event
                    </Text>
                    {/* <Text fontSize={16} fontWeight={400} color={"#747A8B"}>
                        Create an event
                      </Text> */}
                    <Button
                      mt={8}
                      mb={4}
                      size={"lg"}
                      w={"100%"}
                      color="#fff"
                      borderRadius={64}
                      background={"primary"}
                      onClick={onOpen}
                    >
                      Create Event
                    </Button>
                  </Container>
                )}
                {!loading && myEvents.length > 0 && (
                  <>
                  {
                    upcomingEvent &&
                    <Box
                    bg={"primary"}
                    bgImage={circlePattern}
                    bgRepeat={"no-repeat"}
                    bgPosition={"right"}
                    borderRadius={16}
                    px={3}
                    py={5}
                    color={"#fff"}
                    mb={2}
                  >
                    <Text fontSize={18} fontWeight={600} mb={1}>
                      {upcomingEvent?.name}
                    </Text>
                    <Text fontSize={12} fontWeight={400}>
                      {moment(upcomingEvent?.date).format("dddd, Do MMMM YYYY")}
                    </Text>
                  </Box>
                  }
                  </>
                )}

                {myEventsGrouped.map((e, i) => (
                  <Box key={i} mt={2}>
                    <Text
                      py={2}
                      fontSize={14}
                      fontWeight={500}
                      color={"#7353BA"}
                    >
                      {moment(e?.date).format("dddd, Do MMMM YYYY")}
                    </Text>
                    <Divider color={"#EBECEF"} />
                    {e.events.map((event) => (
                      <Box
                        key={event.id}
                        bg={"#F7F7F8"}
                        borderLeft={"2px"}
                        borderLeftColor={"primary"}
                        p={2}
                        mb={2}
                        borderRadius={6}
                        cursor={"pointer"}
                        onClick={() => handleViewEvent(event)}
                      >
                        <Text fontSize={12} fontWeight={500}>
                          {event.name}
                        </Text>
                        <Flex justifyContent={"space-between"}>
                          <Flex alignItems={"center"}>
                            <Box
                              w={3}
                              h={3}
                              bg={"#C7CAD1"}
                              borderRadius={100}
                              mr={1}
                            />
                            <Text
                              fontSize={12}
                              fontWeight={500}
                              color={"#747A8B"}
                            >
                              {moment(event.start_time.replace("T", " ").replace("Z", "")).format("hh:mm a")}
                            </Text>
                          </Flex>
                          <AvatarGroup size="sm" max={2}>
                            {event.guests.map((g, y) => (
                              <Avatar key={g+""+y} name={g} />
                            ))}
                          </AvatarGroup>
                        </Flex>
                      </Box>
                    ))}
                  </Box>
                ))}
                {
                  myEventsGrouped.length == 0 ?
                 <Container
                    maxW="md"
                    width={{ base: "auto", md: "100%" }}
                    textAlign={"center"}
                  >
                    <Center>
                      <Image src={communityImg2} />
                    </Center>
                    <Text mb={2} fontSize={18} fontWeight={600}>
                      No event found
                    </Text>
                    {/* <Text fontSize={16} fontWeight={400} color={"#747A8B"}>
                        Create an event
                      </Text> */}
                  </Container>
                  :""
                }

              </Box>
            )}
          </Box>

          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={"lg"}
            isCentered
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize={18} fontWeight={600}>
                {selectedEvent == null ? "Create" : "Edit"} Event
              </ModalHeader>
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#F6F6F6"}
                  borderRadius={100}
                  color={"#17181C"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody>
                <DatePicker
                  onOk={(value) => {
                    var d = new Date(value);
                    setDisplayedDate(
                      moment(d).format("MMMM Do YYYY, h:mm:ss a")
                    );
                    var dd = moment(d).format("YYYY-MM-D H:mm:ss");
                    setDate(dd);
                    setIsPicker(false);
                  }}
                  open={isPicker}
                  format="yyyy-MM-dd HH:mm:ss a"
                />
                <form
                  onClick={() => {
                    if (isPicker) setIsPicker(false);
                  }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormControl mt={3} isInvalid={errors.name}>
                    <FormLabel htmlFor="name" fontSize={14} fontWeight={600}>
                      Name
                    </FormLabel>
                    <Input
                      id="name"
                      placeholder=""
                      {...register("name", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.name && errors.name.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mt={3} isInvalid={errors.descriptions}>
                    <FormLabel
                      htmlFor="descriptions"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Description {}
                    </FormLabel>
                    <Textarea
                      id="descriptions"
                      placeholder=""
                      {...register("descriptions", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.descriptions && errors.descriptions.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl mt={3} isInvalid={errors.guests}>
                    <FormLabel htmlFor="guests" fontSize={14} fontWeight={600}>
                      Add guests
                    </FormLabel>
                    <EmailChipInput
                      initialEmails={getValues("guests")}
                      callBackFunc={(e) => setValue("guests", e)}
                      updatePlus={(e) => {
                        setPlusClicked(e)
                      }
                      }
                    />
                    <FormErrorMessage>
                      {errors.guests && errors.guests.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mt={3} isInvalid={errors.location}>
                    <FormLabel
                      htmlFor="location"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Location
                    </FormLabel>
                    <Input
                      id="location"
                      placeholder=""
                      {...register("location", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormHelperText fontSize={12} fontWeight={400}>
                      Include the event access link/information for virtual
                      events & event address for physical events.
                    </FormHelperText>
                    <FormErrorMessage>
                      {errors.location && errors.location.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl mt={3} isInvalid={errors.date}>
                    <FormLabel htmlFor="date" fontSize={14} fontWeight={600}>
                      Date & time
                    </FormLabel>

                    <InputGroup>
                      <Input
                        readOnly
                        value={displayedDate}
                        onChange={() => {}}
                        borderColor={"#E3E4E8"}
                        onClick={() => {
                          setIsPicker(!isPicker);
                        }}
                        onFocus={() => setIsPicker(true)}
                        placeholder="Select date "
                      />
                      <InputRightElement>
                        <Icon
                          onClick={() => {
                            setIsPicker(!isPicker);
                          }}
                          as={CalendarDaysIcon}
                        />
                      </InputRightElement>
                    </InputGroup>

                    <FormErrorMessage>
                      {errors.date && errors.date.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Flex>
                    <Spacer />
                    <Button
                      mt={3}
                      textAlign={"right"}
                      borderRadius={100}
                      bg={"primary"}
                      color={"#fff"}
                      p={6}
                      type={"submit"}
                      isLoading={isSubmitting}
                    >
                      {selectedEvent == null ? "Create" : "Edit"} Event
                    </Button>
                  </Flex>
                </form>
              </ModalBody>
            </ModalContent>
          </Modal>

          <CustomModal
            isOpen={isOpenSuccess}
            onClose={onCloseSuccess}
            size={"md"}
            body={
              <Box>
                <Center>
                  <Image src={successGif} />
                </Center>
                <Text textAlign={"center"} fontSize={20} fontWeight={600}>
                  Event Created
                </Text>
                <Text textAlign={"center"} fontSize={16} fontWeight={400}>
                  Your event was created successfully
                </Text>
                <Divider variant={"dashed"} my={2} />
                <Text textAlign={"center"} my={1}>
                  We advise that you link this event with your default phone
                  calendar (gmail, outlook, iCal..)
                </Text>
                <Center></Center>
                <Center>
                  <AddToCalendarButton
                    name={currentEvent.name ?? "Sojorne"}
                    description={
                      currentEvent.description ?? "Sojorne description"
                    }
                    startDate={moment(currentEvent.date).format("YYYY-MM-DD")}
                    options={["Apple", "Google", "Yahoo", "iCal"]}
                    buttonStyle="round"
                    hideIconButton
                    hideBackground
                    styleLight="--btn-background: #7353BA; --btn-text: #fff; --font: Georgia, 'Times New Roman', Times, serif; --width: 100%"
                    styleDark="--btn-background: #000;"
                  ></AddToCalendarButton>
                </Center>
                <Center>
                  <Button
                    mt={2}
                    mb={4}
                    size={"lg"}
                    w={"50%"}
                    color="#747A8B"
                    borderColor={"#EBECEF"}
                    border={"1px solid #EBECEF"}
                    borderRadius={64}
                    background={"#fff"}
                    onClick={onCloseSuccess}
                  >
                    Done
                  </Button>
                </Center>
              </Box>
            }
          />

          <Modal
            isOpen={isOpenView}
            onClose={onCloseView}
            size={"lg"}
            isCentered
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent borderRadius={12}>
              {/* <Box minH={48} bgImg={locationImg} borderRadius={12}></Box> */}
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#17181C"}
                  borderRadius={100}
                  color={"#fff"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody>
                <Text fontSize={18} fontWeight={600} mt={3}>
                  {selectedEvent?.name}
                </Text>
                <Text fontSize={16} fontWeight={500} color={"#747A8B"} mt={3}>
                  Time
                </Text>
                <Text fontSize={16} fontWeight={500} color={"#747A8B"}>
                  {moment(selectedEvent?.start_time.replace("T", " ").replace("Z", "")).format("hh:mm a")}
                </Text>
                <Text fontSize={16} fontWeight={500} color={"#747A8B"} mt={3}>
                  Location
                </Text>
                <Text
                  fontSize={16}
                  fontWeight={500}
                  color={"#FF6E57"}
                  cursor={"pointer"}
                >
                  {isValidUrl(selectedEvent?.location) ? (
                    <a href={selectedEvent?.location}>
                      {selectedEvent?.location}
                    </a>
                  ) : (
                    <a
                      href={`http://maps.google.com/?q=${selectedEvent?.location}`}
                    >
                      {selectedEvent?.location}
                    </a>
                  )}
                </Text>
                <Text fontSize={16} fontWeight={500} color={"#747A8B"} mt={3}>
                  Guests
                </Text>
                {selectedEvent?.guests?.map((e) => (
                  <Text fontSize={16} fontWeight={500} color={"#17181C"}>
                    {e}
                  </Text>
                ))}

                {!isCoCaregiver ? (
                  <Button
                    mt={4}
                    mb={4}
                    size={"lg"}
                    w={"100%"}
                    color="#747A8B"
                    borderColor={"#EBECEF"}
                    border={"1px solid #EBECEF"}
                    borderRadius={64}
                    background={"#fff"}
                    onClick={handleEditEvent}
                  >
                    Edit Event
                  </Button>
                ) : userPermission !== null &&
                  userPermission.includes("calendar:edit") ? (
                  <Button
                    mt={4}
                    mb={4}
                    size={"lg"}
                    w={"100%"}
                    color="#747A8B"
                    borderColor={"#EBECEF"}
                    border={"1px solid #EBECEF"}
                    borderRadius={64}
                    background={"#fff"}
                    onClick={handleEditEvent}
                  >
                    Edit Event
                  </Button>
                ) : null}
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      }
    />
  );
}
