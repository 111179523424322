import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, Link as ReachLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  dashboardRoute,
  forgetPasswordRoute,
  otpRoute,
  resetPasswordRoute,
  signupRoute,
} from "../../utils/allRoutes";
import { setAuth, setTokens } from "../../utils/auth";
import LoginSide from "../../components/loginSide";
import {
  httpGetWithTokenRest,
  httpPostWithoutToken,
} from "../../utils/http_utils";
import ls from "localstorage-slim";
import FamiliesModal from "./families_modal";
import AgreemenModal from "./agreement_modal";
import { AppContext } from "../../redux/appContext";
import SubscriptionSuccessfulNoAuth from "../plans/subscription_successful_no_auth";

function Login() {
  const { updateUser, setSub } = useContext(AppContext);
  const token = ls.get("sj_token", { decrypt: true });
  const navigate = useNavigate();
  const [acceptedAgreement, setAcceptedAgreement] = useState([]);
  const [agreementJorners, setAgreementJorners] = useState([]);
  const [currentSub, setCurrentSub] = useState({});
  const toast = useToast();

  const [families, setFamilies] = React.useState([]);
  const [family, setFamily] = React.useState(null);
  const [user, setUser] = React.useState({});
  const [email, setEmail] = React.useState("");
  const [show, setShow] = React.useState(false);
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("this is new landing");

    var l = window.location.href;
    if (l.includes("trx")) {
      l = l.replace(window.location.host, "");
      l = l.split("trx=");
      l = l[1];
      verifyPayment(l.trim());
    } else {
    }

    if (l.includes("?fam=")) {
      l = l.replace(window.location.host, "");
      l = l.split("fam=");

      let famEmail = l[1];
      let f = famEmail.split("&email=");
      if (f[1]) setValue("email", f[1]);

      fetchFamily(f[0]);
    }

    if (l.includes("?fam=")) {
      l = l.replace(window.location.host, "");
      l = l.split("fam=");
      l = l[1];
      fetchFamily(l.trim());
    }
  }, []);

  useEffect(() => {
    ls.clear();
    sessionStorage.clear();
  }, []);
  const {
    isOpen: isOpenModalA,
    onOpen: onOpenModalA,
    onClose: onCloseModalA,
  } = useDisclosure();

  if (token) {
    return <Navigate to={dashboardRoute} />;
  }

  const handleClick = () => setShow(!show);

  const fetchFamily = async (fam) => {
    var j = await httpGetWithTokenRest("family/" + fam);
    if (j.statusCode == 200) {
      setFamily(j.data);
    } else {
      navigate("/login");
    }
  };

  const verifyPayment = async (payment) => {
    var j = await httpGetWithTokenRest("subscription/status/" + payment);
    if (j.statusCode == 200) {
      if (j.data.status == "paid") {
        onOpenCreate();
        setCurrentSub(j.data);
      } else {
        return toast({
          title: "Sorry, we hit a snag1!",
          description: `${j.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${j.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchUserPermission = async () => {
    const resp = await httpGetWithTokenRest("permissions");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      ls.set("sj_cocg_permission", resp.data.permissions, { encrypt: true });
    }
  };

  const loginToFamily = async (fam) => {
    const resp = await httpPostWithoutToken("caregiver/families/auth", {
      ...fam,
      limitToken: true, // use token with expiration date
    });
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      ls.set("families", families, { encrypt: true });
      ls.set("active_family", fam, { encrypt: true });
      let u = resp.data.user;
      u.phone_number = u.phone_number
        ? u.phone_number.replace("undefined", "")
        : "";
      // ls.set("sj_user", u, {
      //   encrypt: true,
      // });
      // ls.set("sj_token", resp.data.token, { encrypt: true });
      sessionStorage.setItem("sj_user", JSON.stringify(u));
      setTokens(resp.data.token, resp.data.refreshToken);
      setSub(resp.data.subscription);
      window.Atlas.call(
        "identify",
        {
          userId: u.id,
          name: `${u.first_name} ${u.last_name}`,
          email: u.email
        }
      )
      updateUser(u);
      await fetchUserPermission();
      setAuth(dispatch);
      window.location.assign(dashboardRoute);

    }
  };

  async function onSubmit(values) {
    setEmail(values.email);
    const resp = await httpPostWithoutToken("caregiver/auth", {
      ...values,
      limitToken: true, // use token with expiration date
    });
    if (resp.error !== null && resp.error !== undefined) {
      if (resp.status === 403) {
        ls.set("sj_email", values.email, { encrypt: true });

        navigate(otpRoute);
      }

      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      if (resp.data.families) {
        // implement login with families
        const fams = resp.data.families;
        setFamilies(fams);
        onOpenModal();
      } else {
        ls.set("families", null, { encrypt: true });
        setFamilies([]);
        if (resp.data.jorners) {
          if (resp.data.jorners.length > 0) {
            setUser(resp);
            onOpenModalA();
            setAgreementJorners(resp.data.jorners);
            return false;
          } else {
            ls.set("sj_email", email, { encrypt: true });
            ls.set("sj_iscc", true, { encrypt: true });
            ls.set("sj_uid", resp.data.user.user_id, { encrypt: true });
            return navigate(resetPasswordRoute);
          }
        }

        let u = resp.data.user;
        u.phone_number = u.phone_number
          ? u.phone_number.replace("undefined", "")
          : "";
        sessionStorage.setItem("sj_user", JSON.stringify(u));
        setTokens(resp.data.token, resp.data.refreshToken);
        setSub(resp.data.subscription);
        updateUser(u);
        
        window.Atlas.call(
          "identify",
          {
            userId: u.id,
            name: `${u.first_name} ${u.last_name}`,
            email: u.email
          }
        )

        await fetchUserPermission();
        setAuth(dispatch);
        window.location.assign(dashboardRoute);
      }
    }
  }

  return (
    <>
      <Flex min-height={"100vh"} width={"100%"}>
        <SubscriptionSuccessfulNoAuth
          isOpen={isOpenCreate}
          onClose={onCloseCreate}
          sub={currentSub}
          onFinished={null}
        />
        <LoginSide isFamily={family ? true : false} />
        <Box
          zIndex={2}
          w={{ base: "100%", md: "50%" }}
          background={"white"}
          position="relative"
          overflowY={"scroll"}
          flex="1"
          borderTopRadius={{ base: "20px", md: 0 }}
          paddingTop={{ base: "40px", md: 0 }}
          display="flex"
          h={{ base: "calc(100vh - 30px)", md: "100vh" }}
          flexDirection={{ base: "column", md: "row" }}
          m={{ base: "30px 0 0 0", md: "0" }}
        >
          {/* <Box display={{ md: "none" }} borderRadius={20}>
            <Center p={2}>
              <Image src={AppLogo} />
            </Center>
          </Box> */}
          <FamiliesModal
            isOpen={isOpenModal}
            onFinished={(v) => {
              loginToFamily(v);
            }}
            constants={families}
            selectedOriginal={[]}
            onClose={onCloseModal}
          />

          {agreementJorners.length > 0 && (
            <AgreemenModal
              isOpen={isOpenModalA}
              onFinished={() => {
                // do something
                ls.set("sj_email", email, { encrypt: true });
                ls.set("sj_iscc", true, { encrypt: true });
                ls.set("sj_uid", user.data?.user_id, { encrypt: true });
                return navigate(resetPasswordRoute);
              }}
              u={user && user.data?.user}
              cg={user && user.data?.caregiver}
              constants={agreementJorners}
              // selectedOriginal={[]}
              onClose={onCloseModalA}
            />
          )}
          <AbsoluteCenter axis="both" width={"70%"}>
            {family ? (
              <Heading
                pt={8}
                flexWrap="nowrap"
                textAlign={"center"}
                fontWeight={600}
                fontSize="32px"
                color={"black"}
              >
                Welcome to <br />
                The {family.family_name}'s Care Circle
              </Heading>
            ) : (
              <Heading
                pt={8}
                display={"flex"}
                justifyContent="center"
                flexWrap="nowrap"
                fontWeight={600}
                fontSize="32px"
                color={"black"}
              >
                Log In
              </Heading>
            )}
            {family ? (
              ""
            ) : (
              <Text py={8} fontSize={16} fontWeight={400} textAlign={"center"}>
                Welcome back to Sojorne! Sign in and get back to more wins for
                you and your jorner!
              </Text>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl mt={5} isInvalid={errors.email} fontWeight={600}>
                <FormLabel htmlFor="name">Email Address</FormLabel>
                <Input
                  id="email"
                  placeholder="e.g. jane@sojorne.com"
                  {...register("email", {
                    required: "This is required",
                    validate: {
                      matchPattern: (v) =>
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        "Email address must be a valid address",
                    },
                  })}
                  borderColor={"#E3E4E8"}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl mt={5} isInvalid={errors.password} fontWeight={600}>
                <FormLabel htmlFor="name">Password</FormLabel>
                <InputGroup>
                  <Input
                    id="password"
                    type={show ? "text" : "password"}
                    placeholder="e.g Sojorne1234$"
                    {...register("password", {
                      required: "This is required",
                      minLength: {
                        value: 8,
                        message: "Minimum length should be 8",
                      },
                    })}
                    borderColor={"#E3E4E8"}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
              {family ? (
                <Text
                  textAlign={"left"}
                  fontWeight={400}
                  color={"red"}
                  fontSize={12}
                  m={3}
                >
                  Use the system generated password in your Inbox.
                </Text>
              ) : (
                <Text
                  textAlign={"right"}
                  fontWeight={400}
                  color={"black"}
                  fontSize={16}
                  m={3}
                >
                  Forgot Password?
                  <Text as={"span"} color={"secondary"}>
                    <ReachLink to={forgetPasswordRoute}> Reset</ReachLink>
                  </Text>
                </Text>
              )}

              <Button
                mt={8}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#fff"
                borderRadius={64}
                background={"primary"}
                isLoading={isSubmitting}
                type="submit"
              >
                Next
              </Button>

              {family ? (
                <></>
              ) : (
                <Text
                  textAlign={"center"}
                  fontWeight={400}
                  color={"black"}
                  fontSize={16}
                  m={3}
                >
                  Don't have an account?
                  <Text as={"span"} color={"primary"}>
                    <ReachLink to={signupRoute}> Sign Up</ReachLink>
                  </Text>
                </Text>
              )}
            </form>
          </AbsoluteCenter>
        </Box>
      </Flex>
    </>
  );
}

export default Login;
