import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Spacer,
  Spinner,
  Stack,
  Tag,
  Text,
  Textarea,
  Tooltip,
  UnorderedList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import {
  FaEdit,
  FaEllipsisV,
  FaFile,
  FaPlus,
  FaPrint,
  FaUser,
} from "react-icons/fa";
import {
  carePlanImg,
  carePlanPdf,
  deleteIcon,
  mainCarePlanPdf,
  powerAttImg,
} from "../../images";
import {
  BsChevronLeft,
  BsClipboard,
  BsDownload,
  BsFillTrash3Fill,
  BsUpload,
} from "react-icons/bs";
import { Controller, useForm } from "react-hook-form";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { COUNTRIES } from "../../utils/countries";
import {
  httpDeleteWithToken,
  httpGetWithTokenRest,
  httpPatch2WithTokenRest,
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import { BiUserCircle } from "react-icons/bi";
import { httpPatchWithTokenRest } from "../../utils/http_utils";
import { EmailChipInput } from "../../components/emailInput";
import CustomModal from "../../components/customModal";
import { PDFDocument, degrees, rgb, StandardFonts } from "pdf-lib";
import download from "downloadjs";
// import { print } from "pdf-to-printer";
import printJS from "print-js";
import moment from "moment";
import { AppContext } from "../../redux/appContext";
import { useNavigate } from "react-router-dom";
import { containsNumber, containsSpecialChars } from "../../utils/utils";
export default function CarePlan() {
  const { subscription } = useContext(AppContext);
  const [valuePhone, setValuePhone] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("+1");
  const [valuePhoneSP, setValuePhoneSP] = React.useState("");
  const [countryCodeSP, setCountryCodeSP] = React.useState("+1");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenUpload,
    onOpen: onOpenUpload,
    onClose: onCloseUpload,
  } = useDisclosure();

  const {
    isOpen: isOpenConfirmation,
    onOpen: onOpenConfirmation,
    onClose: onCloseConfirmation,
  } = useDisclosure();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const toast = useToast();
  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso,
  }));
  const [carePlan, setCarePlan] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [addCarePlan, setAddCarePlan] = React.useState(false);
  const [jorners, setJorners] = React.useState([]);
  const [parentNote, setParentNote] = React.useState("");
  
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [signatureImg, setSignatureImg] = React.useState(null);
  const [accessedPlanImg, setAccessedPlanImg] = React.useState(null);
  const [selectedCarePlan, setSelectedCarePlan] = React.useState(null);
  const [confirmation, setConfirmation] = React.useState(null);
  const signatureRef = React.useRef();
  const accessedPlanRef = React.useRef();
  const navigate = useNavigate();
  useEffect(() => {
    if (subscription.care_plan == "0") {
      navigate("/access-denied");
    }
  }, []);
  const chooseFile = () => {
    signatureRef.current.click();
  };

  const chooseFilePlan = () => {
    accessedPlanRef.current.click();
  };

  const imageHandler = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setSignatureImg(event.target.files[0]);
    }
  };

  const imageHandlerAP = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setAccessedPlanImg(event.target.files[0]);
    }
  };

  const fetchAllJorners = async () => {
    const resp = await httpGetWithTokenRest("jorner");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error.includes("permission") ? "You do not have permission to view Jorners profile" : resp.error} `,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setJorners(resp.data);
    }
  };

  const fetchAllCarePlan = async () => {
    const resp = await httpGetWithTokenRest("caregivers/care_plan");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setCarePlan(resp.data);
      console.log(resp.data)
      setLoading(false);
    }
  };
  const duplicate = async (id) => {
    const resp = await httpGetWithTokenRest("caregivers/care_plan/copy/" + id);
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      await fetchAllCarePlan();
      setLoading(false);
      return toast({
        title: "Careplan created.",
        description: `New Careplan created successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  async function onSubmit(values) {
    // console.log(values)
    
    if(containsNumber(values.specialty_name)) {
      return toast({
        title: "Speciality Provider's name cannot contain digits",
        status: "error",
        duration: 5000,
        isClosable: true,
      }); 
    }
    if(containsSpecialChars(values.specialty_name)) {
      return toast({
        title: "Speciality Provider's name cannot contain special character",
        status: "error",
        duration: 5000,
        isClosable: true,
      }); 
    }

    if(containsNumber(values.provider_name)) {
      return toast({
        title: "Provider's name cannot contain digits",
        status: "error",
        duration: 5000,
        isClosable: true,
      }); 
    }
    if(parentNote.length > 200) {
      return toast({
        title: "Parent note should be maximum of 200 characters",
        status: "error",
        duration: 5000,
        isClosable: true,
      }); 
    }
    if(containsSpecialChars(values.provider_name)) {
      return toast({
        title: "Provider's name cannot contain special character",
        status: "error",
        duration: 5000,
        isClosable: true,
      }); 
    }
    
    const selectedJorner = jorners.find(
      (e) => e.id === values.jorner_name
    );
    if (subscription.care_plan != "unlimited") {
      const sCareplan = parseInt(subscription.care_plan);
      if (carePlan.length >= sCareplan) {
        return toast({
          title: "Access Denied!",
          description: `Please upgrade your account to create additional care plans.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
    const data = {
      title: values.title,
      basic_information: {
        jorner_name: selectedJorner.first_name,
        jorner_dob: values.jorner_dob,
        diagnosis: values.diagnosis,
        allergies: values.allergies,
        provider_name: values.provider_name,
        provider_phone_number: countryCode + "" + valuePhone,
        specialty_name: values.specialty_name,
        specialty_phone_number: countryCodeSP + "" + valuePhoneSP,
      },
      needed_accomodation: {
        diet: values.diet,
        classroom_activities: values.classroom_activities,
        nap_time: values.nap_time,
        toileting: values.toileting,
        field_trips: values.field_trips,
        behavior_changes: values.behavior_changes,
        transportation: values.transportation,
        comments: values.comments,
      },
      parent_notes: {
        note: parentNote,
        date: values.signatureDate,
      },
      emergency_care: {
        measures: [values.measures],
        symptoms: [values.symptoms, values.symptoms1],
      },
      signature: "signatureImg",
    };
    let resp;
    if (selectedCarePlan == null) {
      const jornerId = selectedJorner?.id;
      resp = await httpPostWithToken(
        `caregivers/care_plan/${jornerId}?status=draft`,
        data
      );
    } else {
      resp = await httpPutWithTokenRest(
        `caregivers/care_plan/${selectedCarePlan?.jorner_id}/${selectedCarePlan?.id}?status=draft`,
        data
      );
    }

    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setValue("title", "");
      setValue("jorner_name", "");
      setValue("jorner_dob", "");
      setValue("diagnosis", "");
      setValue("allergies", "");
      setValue("provider_name", "");
      setValue("countryCode", "");
      setValue("valuePhone", "");
      setValue("specialty_name", "");
      setValue("countryCodeSP", "");
      setValue("valuePhoneSP", "");
      setParentNote("")
      toast({
        title: "Success.",
        description: `Care plan added successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onOpen();
      reset();
      fetchAllCarePlan();
    }
  }

  const {
    handleSubmit: handleSubmitUpload,
    register: registerUpload,
    reset: resetUpload,
    formState: { errors: uploadErrors, isSubmitting: isSubmittingUpload },
  } = useForm();

  async function onSubmitUpload(values) {
    const formData = new FormData();
    formData.append("file", accessedPlanImg);
    const resp = await httpPatchWithTokenRest(
      `caregivers/care_plan/${selectedCarePlan?.id}`,
      formData
    );

    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Success.",
        description: `Care plan updated successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onCloseUpload();
      onOpenConfirmation();
      fetchAllCarePlan();
    }
  }

  const handleEditCarePlan = (carePlan) => {
    setSelectedCarePlan(carePlan);
    reset({
      title: carePlan.title,
      jorner_name: carePlan.jorner_id,
      jorner_dob: carePlan.basic_information?.jorner_dob,
      diagnosis: carePlan.basic_information?.diagnosis,
      allergies: carePlan.basic_information?.allergies,
      provider_name: carePlan.basic_information?.provider_name,
      provider_phone_number: carePlan.basic_information?.provider_phone_number,
      specialty_name: carePlan.basic_information?.specialty_name,
      specialty_phone_number:
        carePlan.basic_information?.specialty_phone_number,

      diet: carePlan.needed_accomodation?.diet,
      classroom_activities: carePlan.needed_accomodation?.classroom_activities,
      nap_time: carePlan.needed_accomodation?.nap_time,
      toileting: carePlan.needed_accomodation?.toileting,
      field_trips: carePlan.needed_accomodation?.field_trips,
      behavior_changes: carePlan.needed_accomodation?.behavior_changes,
      transportation: carePlan.needed_accomodation?.transportation,
      comments: carePlan.needed_accomodation?.comments,

      note: carePlan.parent_notes?.note,
      signatureDate: carePlan.parent_notes?.date,
      measures: carePlan.emergency_care?.measures,
      symptoms: carePlan.emergency_care?.symptoms[0],
      symptoms1: carePlan.emergency_care?.symptoms[1],
    });
    setParentNote(carePlan.parent_notes?.note)
    setAddCarePlan(true);
  };

  const handleDownloadCarePlan = () => {
    const lastCare = carePlan.slice(-1);
    editPdf(lastCare[0]);
    fetchAllCarePlan();
    setAddCarePlan(false);
    onClose();
  };

  const handleDeleteCarePlan = async (carePlanId) => {
    setIsDeleting(true);
    const resp = await httpDeleteWithToken(
      `caregivers/care_plan/${carePlanId}`
    );
    if (resp.error !== null && resp.error !== undefined) {
      setIsDeleting(false);
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setIsDeleting(false);
      fetchAllCarePlan();
      onCloseDelete();
      return toast({
        title: "Success.",
        description: `Plan deleted successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const editPdf = async (e, type = "download") => {
    console.log("e", e);

    const formUrl = window.location.href.includes("localhost")
      ? carePlanPdf
      : "https://api.sojorne.com/api/v1/pdf/careplan.pdf";
    const formUrl2 = mainCarePlanPdf;
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    const form = pdfDoc.getForm();
    const fields = form.getFields();

    const dateField = form.getTextField("date");
    const child_nameField = form.getTextField("child_name");
    const dobField = form.getTextField("dob");
    const parent_nameField = form.getTextField("parent_name");
    const primary_healthcare_providerField = form.getTextField(
      "primary_healthcare_provider"
    );
    const specialty_provider1Field = form.getTextField("specialty_provider_1");
    const specialty_provider2Field = form.getTextField("specialty_provider2");

    const parent_phoneField = form.getTextField("parent_phone");
    const primary_healthcare_provider_phoneField = form.getTextField(
      "primary_healthcare_provider_phone"
    );
    const specialty_provider1_phoneField = form.getTextField(
      "specialty_provider1_phone"
    );
    const specialty_provider2_phoneField = form.getTextField(
      "specialty_provider2_phone"
    );
    const dietField = form.getTextField("diet");
    const classroomField = form.getTextField("classroom");
    const naptimeField = form.getTextField("naptime");
    const toiletingField = form.getTextField("toileting");
    const outdoorField = form.getTextField("outdoor");
    const transportationField = form.getTextField("transportation");
    const behaviouralField = form.getTextField("behavioural");
    const commentsField = form.getTextField("comments");
    const symptom_parentField = form.getTextField("symptom_parent");
    const symptom_emergency1Field = form.getTextField("symptom_emergency1");
    const symptom_emergency2Field = form.getTextField("symptom_emergency2");
    const measure_parentField = form.getTextField("measure_parent");
    const measure_parent2Field = form.getTextField("measure_parent2");

    //setting the fields
    dateField.setText(e?.basic_information?.jorner_dob);
    child_nameField.setText(e?.basic_information?.jorner_name);
    dobField.setText(e?.basic_information?.jorner_dob);

    primary_healthcare_providerField.setText(
      e?.basic_information?.provider_name
    );
    primary_healthcare_provider_phoneField.setText(
      e?.basic_information?.provider_phone_number
    );

    specialty_provider1Field.setText(e?.basic_information?.specialty_name);
    specialty_provider1_phoneField.setText(
      e?.basic_information?.specialty_phone_number
    );

    dietField.setText(e?.needed_accomodation?.diet?.join(","));
    classroomField.setText(
      e?.needed_accomodation?.classroom_activities?.join(",")
    );
    naptimeField.setText(e?.needed_accomodation?.nap_time);
    toiletingField.setText(e?.needed_accomodation?.toileting);
    transportationField.setText(e?.needed_accomodation?.transportation);
    outdoorField.setText(e?.needed_accomodation?.field_trips);
    behaviouralField.setText(e?.needed_accomodation?.behavior_changes);
    commentsField.setText(e?.needed_accomodation?.comments);

    symptom_parentField.setText(e?.emergency_care?.symptoms[0]);
    symptom_emergency1Field.setText(e?.emergency_care?.symptoms[1]);
    measure_parentField.setText(e?.emergency_care?.measures[0]);
    console.log("3", "here you go");
    const pdfBytes = await pdfDoc.save();

    download(pdfBytes, "care_plan.pdf", "application/pdf");
  };

  React.useEffect(() => {
    fetchAllJorners();
    fetchAllCarePlan();
  }, []);

  return (
    <Box>
      {!addCarePlan ? (
        <>
          {carePlan ? (
            <>
              {carePlan?.length < 1 ? (
                <Box>
                  <Text color={"#FF6E57"} fontSize={20} fontWeight={600} my={1}>
                    Care Plan
                  </Text>
                  <Text fontSize={14} fontWeight={400} color={"#747A8B"} mb={5}>
                    Your care plan is to help provide guidelines for care of
                    your dependents.
                  </Text>
                  <Box>
                    {carePlan?.length > 0 ? (
                      carePlan.map((e, i) => <></>)
                    ) : (
                      <Box mt={12}>
                        {loading ? (
                          <Stack mt={5}>
                            <Skeleton startColor="#808080" height="50px" />
                            <Skeleton startColor="#808080" height="50px" />
                            <Skeleton startColor="#808080" height="50px" />
                          </Stack>
                        ) : (
                          <>
                            <Center>
                              <Image src={powerAttImg} />
                            </Center>
                            <Text
                              textAlign={"center"}
                              fontSize={16}
                              fontWeight={600}
                            >
                              Create Jorners care plan in 5 minutes
                            </Text>
                            <Text
                              textAlign={"center"}
                              fontSize={14}
                              fontWeight={400}
                              color={"paragraph"}
                              mt={3}
                            >
                              Your care plan is to help provide guidelines for
                              care of your dependents.
                            </Text>
                          </>
                        )}
                      </Box>
                    )}
                    <Center>
                      <Button
                        mt={10}
                        mb={4}
                        size={"lg"}
                        w={"50%"}
                        color="#fff"
                        borderRadius={64}
                        background={"primary"}
                        onClick={() => {
                          if (subscription.care_plan != "unlimited") {
                            const sCareplan = parseInt(subscription.care_plan);
                            if (carePlan.length >= sCareplan) {
                              return toast({
                                title: "Access Denied!",
                                description: `Please upgrade your account to create additional care plans.`,
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                              });
                            }
                          }
                          setValue("title", "");
                          setSelectedCarePlan(null);
                          setAddCarePlan(true);
                        }}
                      >
                        Create Care Plan
                      </Button>
                    </Center>
                    <Text textAlign={"center"} fontSize={12} fontWeight={400}>
                      {" "}
                      Disclaimer: Note that Sojorne only provides a care plan
                      draft.
                    </Text>
                  </Box>
                </Box>
              ) : (
                <Box px={{ base: 8, md: 24 }}>
                  <Text
                    color={"#FF6E57"}
                    fontSize={20}
                    fontWeight={600}
                    mt={1}
                    mb={5}
                  >
                    Care Plan
                  </Text>

                  {carePlan.map((e, i) => (
                    <Box
                      borderRadius={16}
                      p={6}
                      border={"1px solid #EBECEF"}
                      key={i}
                    >
                      <Flex alignItems={"center"}>
                        <Text fontWeight={600} fontSize={14} mr={1}>
                          {e?.title}
                        </Text>{" "}
                        <Tag
                          bg={
                            e?.status === "completed" ? "lightgreen" : "orange"
                          }
                          borderRadius={8}
                          color={"black"}
                        >
                          {e?.status == "awaiting_approval"
                            ? "draft"
                            : e?.status}
                        </Tag>
                        <Spacer />
                        <Menu>
                          <MenuButton
                            as={Button}
                            rightIcon={<FaEllipsisV color={"#747A8B"} />}
                          >
                            {" "}
                          </MenuButton>
                          <MenuList border={"none"} boxShadow={"md"}>
                            {e?.status === "draft" ||
                            e?.status === "awaiting_approval" ? (
                              <MenuItem onClick={() => handleEditCarePlan(e)}>
                                <Flex alignItems={"center"} color={"#747A8B"}>
                                  <FaEdit />
                                  <Text ml={1}>Continue</Text>
                                </Flex>
                              </MenuItem>
                            ) : null}

                            <MenuItem
                              onClick={() => {
                                editPdf(e, "print");
                              }}
                            >
                              <Flex alignItems={"center"} color={"#747A8B"}>
                                <FaPrint />
                                <Text ml={1}>Print Plan </Text>
                              </Flex>
                            </MenuItem>
                            {
                              e?.status != "completed"
                              &&
                            <MenuItem
                              onClick={() => {
                                setSelectedCarePlan(e);
                                onOpenUpload();
                              }}
                              key={"uap"}
                            >
                              <Flex alignItems={"center"} color={"#747A8B"}>
                                <BsUpload />
                                <Text ml={1}>Upload Signed Plan</Text>
                              </Flex>
                            </MenuItem>
                            }
                            <MenuItem
                              onClick={() => {
                                duplicate(e.id);
                              }}
                            >
                              <Flex alignItems={"center"} color={"#747A8B"}>
                                <BsClipboard />
                                <Text ml={1}>Duplicate</Text>
                              </Flex>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                editPdf(e);
                              }}
                            >
                              <Flex alignItems={"center"} color={"#747A8B"}>
                                <BsDownload />
                                <Text ml={1}>Download Plan</Text>
                              </Flex>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setSelectedCarePlan(e);
                                onOpenDelete();
                              }}
                            >
                              {isDeleting ? (
                                <Spinner />
                              ) : (
                                <Flex alignItems={"center"} color={"#747A8B"}>
                                  <BsFillTrash3Fill />
                                  <Text ml={1}>Delete Plan</Text>
                                </Flex>
                              )}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Flex>
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Box>
                          <Text color={"#747A8B"} mb={3}>
                            Your care plan is to provide guidelines <br /> for
                            care of your jorners.
                          </Text>
                          <Flex alignItems={"center"}>
                            <BiUserCircle /> {e?.basic_information?.jorner_name}
                          </Flex>
                        </Box>
                        <Image src={carePlanImg} h={"63px"} w={"52px"} />
                      </Flex>
                    </Box>
                  ))}

                  <Button
                    mt={8}
                    mb={4}
                    size={"lg"}
                    w={"100%"}
                    color="#fff"
                    borderRadius={64}
                    background={"primary"}
                    onClick={() => {
                      if (subscription.care_plan != "unlimited") {
                        const sCareplan = parseInt(subscription.care_plan);
                        if (carePlan.length >= sCareplan) {
                          return toast({
                            title: "Access Denied!",
                            description: `Please upgrade your account to create additional care plans.`,
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                          });
                        }
                      }
                      setSelectedCarePlan(null);
                      setAddCarePlan(true);
                    }}
                  >
                    Create Care Plan
                  </Button>
                  <Text textAlign={"center"} fontSize={12} fontWeight={400}>
                    Disclaimer: Note that Sojorne only provides a care plan
                    draft.
                  </Text>
                </Box>
              )}
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        <Box px={{ base: 2, md: 12 }} py={3}>
          <Flex
            alignItems={"center"}
            gap={2}
            cursor={"pointer"}
            onClick={() => {
              fetchAllCarePlan();
              fetchAllJorners();
              setAddCarePlan(false);
            }}
            w={28}
          >
            <BsChevronLeft color="#17181C" />
            <Text>Back</Text>
          </Flex>
          <Box h={6} />
          <Text color={"#FF6E57"} fontSize={20} fontWeight={600} my={1}>
            {selectedCarePlan ? "Edit Care Plan" : "Create Care Plan"}
          </Text>
          <Text fontSize={14} fontWeight={400} color={"#747A8B"} mb={5}>
            Start creating your care plan now in few minutes. You can save to
            draft to continue later.
          </Text>
          <Box h={6} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Accordion defaultIndex={[0]} allowMultiple={true}>
              <AccordionItem
                key={`basicInfo`}
                border={"1px solid white"}
                mb={4}
              >
                <h2>
                  <AccordionButton bg={"#F7F7F8"}>
                    <Text flex="1" textAlign={"left"}>
                      Basic information{" "}
                      <Text as={"span"} color={"#DB504A"}>
                        *
                      </Text>
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  pb={4}
                  bg={"#FAF8FF"}
                  h={"500px"}
                  overflowY={"scroll"}
                >
                  <FormControl isInvalid={errors.title} mt={4}>
                    <FormLabel
                      htmlFor="careplan_title"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Title
                    </FormLabel>
                    <Input
                      id="title"
                      type="text"
                      placeholder="e.g Careplan for Andrew"
                      {...register("title", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                  </FormControl>

                  <FormControl isInvalid={errors.jorner_name} mt={4}>
                    <FormLabel
                      htmlFor="jorner_name"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Jorner
                    </FormLabel>
                    <Select
                      id="jorner_name"
                      placeholder="e.g Select Jorner"
                      {...register("jorner_name", {
                        required: "This is required",
                      })}
                      onChange={(e)=> {
                        let j = e.target.value;
                        if(j !== "") {
                          let jorner = jorners.find((l)=> l.id === j);
                          let dob = jorner.dob
                          setValue("jorner_dob", dob)
                        }
                      }}
                      borderColor={"#E3E4E8"}
                    >
                      {jorners?.map((e, i) => (
                        <option value={e?.id}>{e?.first_name}</option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {errors.jorner_name && errors.jorner_name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.jorner_dob} mt={4}>
                    <FormLabel
                      htmlFor="jorner_dob"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Jorner's Date of Birth
                    </FormLabel>
                    <Input
                      id="jorner_dob"
                      type="date"
                      placeholder="e.g Simon"
                      {...register("jorner_dob", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.jorner_dob && errors.jorner_dob.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.diagnosis} mt={4}>
                    <FormLabel
                      htmlFor="diagnosis"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Diagnosis(es){" "}
                      <Tooltip
                        bg={"#fff"}
                        colorScheme={"#fff"}
                        label="These are the official diagnosis that your child/loved one has been given by a licensed health professional"
                        color={"#000"}
                      >
                        <InfoOutlineIcon color={"gold"} />
                      </Tooltip>
                    </FormLabel>
                    <EmailChipInput
                      initialEmails={getValues("diagnosis")}
                      callBackFunc={(e) => setValue("diagnosis", e)}
                      placeholder={"e.g Headache"}
                      isEmail={false}
                    />
                    <FormHelperText fontSize={12}>
                      Use comma(,) to separate the items.
                    </FormHelperText>
                    <FormErrorMessage>
                      {errors.diagnosis && errors.diagnosis.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.allergies} mt={4}>
                    <FormLabel
                      htmlFor="allergies"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Allergies{" "}
                      <Tooltip
                        bg={"#fff"}
                        colorScheme={"#fff"}
                        label="Input allergies as revealed by allergy test or observation of your child/loved one."
                        color={"#000"}
                      >
                        <InfoOutlineIcon color={"gold"} />
                      </Tooltip>
                    </FormLabel>
                    <EmailChipInput
                      initialEmails={getValues("allergies")}
                      callBackFunc={(e) => setValue("allergies", e)}
                      placeholder={"e.g Headache"}
                      isEmail={false}
                    />
                    {/* <Input
                      id="allergies"
                      placeholder="e.g Simon"
                      {...register("allergies", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    /> */}
                    <FormHelperText fontSize={12}>
                      Use comma(,) to separate the items.
                    </FormHelperText>
                    <FormErrorMessage>
                      {errors.allergies && errors.allergies.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.provider_name} mt={4}>
                    <FormLabel
                      htmlFor="provider_name"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Provider Name{" "}
                      <Tooltip
                        bg={"#fff"}
                        colorScheme={"#fff"}
                        label="i.e Doctor, School or Therapy Provider"
                        color={"#000"}
                      >
                        <InfoOutlineIcon color={"gold"} />
                      </Tooltip>
                    </FormLabel>
                    <Input
                      id="provider_name"
                      placeholder="e.g Simon"
                      {...register("provider_name", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.provider_name && errors.provider_name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mt={5}
                    isInvalid={errors.phone}
                    fontSize={14}
                    fontWeight={600}
                  >
                    <FormLabel htmlFor="phone" fontSize={14} fontWeight={600}>
                      Provider Phone Number
                    </FormLabel>
                    <PhoneNumberInput
                      key={"phoneInput"}
                      id={"phone"}
                      country={"USA"}
                      value={valuePhone}
                      defaultCountry={countryCode}
                      options={countryOptions}
                      placeholder="Enter phone number"
                      onChange={(value) => setValuePhone(value)}
                      onChangeCode={(value) => setCountryCode(value)}
                    />
                    <FormErrorMessage>
                      {errors.phone && errors.phone.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.specialty_name} mt={4}>
                    <FormLabel
                      htmlFor="specialty_name"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Specialty Provider
                      <Tooltip
                        bg={"#fff"}
                        colorScheme={"#fff"}
                        label="i.e Cardiologist, ENT, Neurologist (Doctors you generally need a referral to see)"
                        color={"#000"}
                      >
                        <InfoOutlineIcon color={"gold"} />
                      </Tooltip>
                    </FormLabel>
                    <Input
                      id="specialty_name"
                      placeholder="e.g Simon"
                      {...register("specialty_name", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.specialty_name && errors.specialty_name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mt={5}
                    isInvalid={errors.phone}
                    fontSize={14}
                    fontWeight={600}
                  >
                    <FormLabel htmlFor="phone" fontSize={14} fontWeight={600}>
                      Specialty Phone Number
                    </FormLabel>
                    <PhoneNumberInput
                      key={"phoneInput"}
                      id={"phone"}
                      country={"USA"}
                      value={valuePhoneSP}
                      defaultCountry={countryCodeSP}
                      options={countryOptions}
                      placeholder="Enter phone number"
                      onChange={(value) => setValuePhoneSP(value)}
                      onChangeCode={(value) => setCountryCodeSP(value)}
                    />
                    <FormErrorMessage>
                      {errors.phone && errors.phone.message}
                    </FormErrorMessage>
                  </FormControl>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem
                key={`accommodation`}
                border={"1px solid white"}
                mb={4}
              >
                <h2>
                  <AccordionButton bg={"#F7F7F8"}>
                    <Text flex="1" textAlign={"left"}>
                      Needed Accommodation{" "}
                      <Text as={"span"} color={"#DB504A"}>
                        *
                      </Text>
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  pb={4}
                  bg={"#FAF8FF"}
                  h={"500px"}
                  overflowY={"scroll"}
                >
                  <FormControl isInvalid={errors.diet} mt={4}>
                    <FormLabel htmlFor="diet" fontSize={14} fontWeight={600}>
                      Diet or Feeding{" "}
                      <Tooltip
                        bg={"#fff"}
                        colorScheme={"#fff"}
                        label="Enter any feeding schedule and related notes, i.e Jimmy eats mashed plant-based foods at 8 am, 12 pm, 6 pm with two 3 oz liquid snacks in between."
                        color={"#000"}
                      >
                        <InfoOutlineIcon color={"gold"} />
                      </Tooltip>
                    </FormLabel>
                    <EmailChipInput
                      initialEmails={getValues("diet")}
                      callBackFunc={(e) => setValue("diet", e)}
                      placeholder={"e.g Beans"}
                      isEmail={false}
                    />
                    {/* <Input
                      id="diet"
                      placeholder="e.g Simon"
                      {...register("diet", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    /> */}
                    <FormHelperText fontSize={12}>
                      Use comma(,) to separate the items.
                    </FormHelperText>
                    <FormErrorMessage>
                      {errors.diet && errors.diet.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.classroom_activities} mt={4}>
                    <FormLabel
                      htmlFor="classroom_activities"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Classroom Activities{" "}
                    </FormLabel>
                    <EmailChipInput
                      initialEmails={getValues("classroom_activities")}
                      callBackFunc={(e) => setValue("classroom_activities", e)}
                      placeholder={"e.g Reading"}
                      isEmail={false}
                    />
                    {/* <Input
                      id="classroom_activities"
                      placeholder=""
                      {...register("classroom_activities", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    /> */}
                    <FormHelperText fontSize={12}>
                      Use comma(,) to separate the items.
                    </FormHelperText>
                    <FormErrorMessage>
                      {errors.classroom_activities &&
                        errors.classroom_activities.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.nap_time} mt={4}>
                    <FormLabel
                      htmlFor="nap_time"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Nap time/Sleeping{" "}
                    </FormLabel>
                    <Input
                      id="nap_time"
                      placeholder="2-3pm"
                      _placeholder={{ color: "#E3E4E8" }}
                      {...register("nap_time", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.nap_time && errors.nap_time.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.toileting} mt={4}>
                    <FormLabel
                      htmlFor="toileting"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Toileting{" "}
                    </FormLabel>
                    <Input
                      id="toileting"
                      {...register("toileting", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.toileting && errors.toileting.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.field_trips} mt={4}>
                    <FormLabel
                      htmlFor="field_trips"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Outdoor or Field Trips{" "}
                    </FormLabel>
                    <Input
                      id="field_trips"
                      {...register("field_trips", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.field_trips && errors.field_trips.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.transportation} mt={4}>
                    <FormLabel
                      htmlFor="transportation"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Transportation{" "}
                      <Tooltip
                        bg={"#fff"}
                        colorScheme={"#fff"}
                        label="Transportation"
                        color={"#000"}
                      >
                        <InfoOutlineIcon color={"gold"} />
                      </Tooltip>
                    </FormLabel>
                    <Input
                      id="transportation"
                      {...register("transportation", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.transportation && errors.transportation.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.behavior_changes} mt={4}>
                    <FormLabel
                      htmlFor="behavior_changes"
                      fontSize={14}
                      fontWeight={600}
                    >
                      For Behavior changes{" "}
                      <Tooltip label="behavior_changes" color={"#000"}>
                        <InfoOutlineIcon color={"gold"} />
                      </Tooltip>
                    </FormLabel>
                    <Input
                      id="behavior_changes"
                      {...register("behavior_changes", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.behavior_changes &&
                        errors.behavior_changes.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.comments} mt={4}>
                    <FormLabel
                      htmlFor="comments"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Additional Comments{" "}
                      <Tooltip label="comments" color={"#000"}>
                        <InfoOutlineIcon color={"gold"} />
                      </Tooltip>
                    </FormLabel>
                    <Input
                      id="comments"
                      {...register("comments", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />
                    <FormErrorMessage>
                      {errors.comments && errors.comments.message}
                    </FormErrorMessage>
                  </FormControl>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem
                key={`emergency`}
                border={"1px solid white"}
                mb={4}
              >
                <h2>
                  <AccordionButton bg={"#F7F7F8"}>
                    <Text flex="1" textAlign={"left"}>
                      Emergency care{" "}
                      <Text as={"span"} color={"#DB504A"}>
                        *
                      </Text>
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  pb={4}
                  bg={"#FAF8FF"}
                  h={"500px"}
                  overflowY={"scroll"}
                >
                  <Text size={12}>
                    Call parent/guardians if the following symptoms are present
                  </Text>
                  <FormControl isInvalid={errors.symptoms} mt={4}>
                    <FormLabel
                      htmlFor="symptoms"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Describe Symptom{" "}
                    </FormLabel>
                    <Textarea
                      id="symptoms"
                      placeholder="Type here..."
                      {...register("symptoms", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />

                    <FormErrorMessage>
                      {errors.symptoms && errors.symptoms.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Divider my={4} variant={"dashed"} />

                  <Text fontSize={12}>
                    Call 911 (Emergency Medical Services) if the following
                    symptoms are present, as well as contacting
                    parent/guardians:
                  </Text>

                  <FormControl isInvalid={errors.symptoms1} mt={4}>
                    <FormLabel
                      htmlFor="symptoms1"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Describe Symptom{" "}
                    </FormLabel>
                    <Textarea
                      id="symptoms1"
                      placeholder="Type here..."
                      {...register("symptoms1", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />

                    <FormErrorMessage>
                      {errors.symptoms1 && errors.symptoms1.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Divider my={4} variant={"dashed"} />

                  <Text fontSize={12}>
                    Take these measures while waiting for parents or medical
                    help to arrive:
                  </Text>

                  <FormControl isInvalid={errors.measures} mt={4}>
                    <FormLabel
                      htmlFor="measures"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Describe Measures to be taken{" "}
                    </FormLabel>
                    <Textarea
                      id="measures"
                      placeholder="Type here..."
                      {...register("measures", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />

                    <FormErrorMessage>
                      {errors.measures && errors.measures.message}
                    </FormErrorMessage>
                  </FormControl>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem key={`notes`} border={"1px solid white"} mb={4}>
                <h2>
                  <AccordionButton bg={"#F7F7F8"}>
                    <Text flex="1" textAlign={"left"}>
                      Parent notes{" "}
                      <Text as={"span"} color={"#DB504A"}>
                        *
                      </Text>
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} bg={"#FAF8FF"}>
                  <FormControl isInvalid={errors.note} mt={4}>
                    <FormLabel htmlFor="note" fontSize={14} fontWeight={600}>
                      Notes{" "}
                    </FormLabel>
                    <Textarea
                    onChange={(e)=> {
                      setParentNote(e.target.value)
                    }}
                      id="note"
                      value={parentNote}
                      placeholder="Type here..."
                      borderColor={parentNote.length > 200 ? "red" : "#E3E4E8"}
                    />

                    <FormErrorMessage>
                      {errors.note && errors.note.message}
                    </FormErrorMessage>
                  </FormControl>
                  <Box h={1} />
                  <Text color={parentNote.length > 200 ? "red" : ""} fontSize={12}>{parentNote.length}/200</Text>
                  <Box h={4} />
                  <Text fontSize={12}>
                    I hereby give consent for my child's health care provider or
                    specialist to communicate with my child's child care
                    provider to discuss any of the information contained in this
                    care plan
                  </Text>

                  <Box
                    border={"1px dashed #E3E4E8"}
                    h={32}
                    mt={4}
                    onClick={chooseFile}
                    cursor={"pointer"}
                  >
                    <Center mt={8}>
                      <Text fontSize={14} color={"secondary"}>
                        Upload Signature
                      </Text>
                    </Center>
                    <Text textAlign={"center"} fontSize={12}>
                      Formats: jpg & png <br /> {signatureImg?.name}
                    </Text>
                  </Box>
                  <Input
                    ref={signatureRef}
                    type={"file"}
                    accept="image/*"
                    onChange={(e) => imageHandler(e)}
                    hidden
                  />

                  <FormControl isInvalid={errors.signatureDate} mt={4}>
                    <FormLabel
                      htmlFor="signatureDate"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Date{" "}
                    </FormLabel>
                    <Input
                      id="signatureDate"
                      type="date"
                      placeholder="Type here..."
                      {...register("signatureDate", {
                        required: "This is required",
                      })}
                      borderColor={"#E3E4E8"}
                    />

                    <FormErrorMessage>
                      {errors.signatureDate && errors.signatureDate.message}
                    </FormErrorMessage>
                  </FormControl>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <div
              style={{
                position: "sticky",
                bottom: 0,
                background: "white",
              }}
            >
              <Button
                mt={8}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#fff"
                borderRadius={64}
                background={"primary"}
                isLoading={isSubmitting}
                type="submit"
              >
                Save & Download
              </Button>
              <Button
                mt={1}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#747A8B"
                borderRadius={64}
                background={"#fff"}
                border={"1px solid #EBECEF"}
                isLoading={isSubmitting}
                type="submit"
              >
                Save as Draft
              </Button>
            </div>
          </form>

          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={"xl"}
            isCentered
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent borderRadius={16}>
              <ModalHeader></ModalHeader>
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#F6F6F6"}
                  borderRadius={100}
                  color={"#17181C"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody>
                <Box>
                  <Text textAlign={"center"} fontSize={16} fontWeight={600}>
                    Save & Plan
                  </Text>
                  <Text
                    textAlign={"center"}
                    fontSize={14}
                    fontWeight={400}
                    color={"#747A8B"}
                  >
                    Download and share your care plan
                  </Text>
                  <Center>
                    <Box
                      h={12}
                      w={"50%"}
                      bg={"#F7F7F8"}
                      textAlign={"center"}
                      p={4}
                    >
                      <Text fontSize={7} fontWeight={600}>
                        SPECIAL NEEDS HEALTH CARE PLAN
                      </Text>
                      <Text fontSize={5} fontWeight={500} color={"#7353BA"}>
                        - To be approved by a Health Care Provider -
                      </Text>
                    </Box>
                  </Center>
                  <Center mt={2}>
                    <Image src={carePlanImg} />
                  </Center>
                  <Box h={4} />
                  <Divider variant={"dashed"} color={"#747A8B"} />
                  <Box h={4} />
                  <Text fontSize={14} fontWeight={600}>
                    Next steps after downloading{" "}
                  </Text>
                  <UnorderedList color={"#747A8B"} mt={3}>
                    <ListItem>Print the downloaded pdf file</ListItem>
                    <ListItem>
                      Take file to your provider to assess and fill
                    </ListItem>
                    <ListItem>
                      Re-upload care plan on the app under the “care plan”
                      section in your account
                    </ListItem>
                  </UnorderedList>
                </Box>
                <Button
                  mt={8}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  //   isLoading={isLoadingJoinCommunity}
                  onClick={handleDownloadCarePlan}
                >
                  Download Plan
                </Button>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
      {/* upload modal */}
      <Modal
        key={"uploadAccess"}
        isOpen={isOpenUpload}
        onClose={onCloseUpload}
        size={"xl"}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius={16}>
          <ModalHeader>Upload Assessed Plan</ModalHeader>
          <ModalCloseButton>
            <Box
              p={2}
              mr={5}
              bg={"#F6F6F6"}
              borderRadius={100}
              color={"#17181C"}
              fontWeight={500}
              fontSize={14}
            >
              Close
            </Box>
          </ModalCloseButton>
          <ModalBody>
            <form onSubmit={handleSubmitUpload(onSubmitUpload)}>
              {/* <FormControl isInvalid={uploadErrors.careplan_title} mt={4}>
                <FormLabel
                  htmlFor="careplan_title"
                  fontSize={14}
                  fontWeight={600}
                >
                  Title
                </FormLabel>
              </FormControl> */}
              <FormControl isInvalid={uploadErrors.jorner_name} mt={4}>
                <FormLabel htmlFor="jorner_name" fontSize={14} fontWeight={600}>
                  Jorner
                </FormLabel>
                <Select
                  id="jorner_name"
                  placeholder="e.g Select Jorner"
                  {...registerUpload("jorner_name", {
                    required: "This is required",
                  })}
                  borderColor={"#E3E4E8"}
                >
                  {jorners?.map((e, i) => (
                    <option value={e?.first_name}>{e?.first_name}</option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {uploadErrors.jorner_name && uploadErrors.jorner_name.message}
                </FormErrorMessage>
              </FormControl>

              <Box
                border={"1px dashed #E3E4E8"}
                h={32}
                mt={4}
                onClick={chooseFilePlan}
                cursor={"pointer"}
              >
                <Center mt={8}>
                  <Text fontSize={14} color={"secondary"}>
                    Browse file
                  </Text>
                </Center>
                <Text textAlign={"center"} fontSize={12}>
                  Formats: pdf
                </Text>
              </Box>
              <Input
                ref={accessedPlanRef}
                type={"file"}
                onChange={(e) => imageHandlerAP(e)}
                hidden
              />
              {accessedPlanImg ? (
                <Box
                  display={"flex"}
                  border={"1px solid #E3E4E8"}
                  p={4}
                  mt={2}
                  alignItems={"center"}
                  gap={3}
                >
                  <FaFile />
                  <Text>
                    {accessedPlanImg?.name} <br />{" "}
                    <Text fontSize={12} fontWeight={400} color={"#747A8B"}>
                      {accessedPlanImg?.size / 1000} kb
                    </Text>
                  </Text>
                </Box>
              ) : null}

              <Button
                mt={8}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#fff"
                borderRadius={64}
                background={"primary"}
                isLoading={isSubmittingUpload}
                isDisabled={accessedPlanImg == null}
                type="submit"
              >
                Upload
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* confirmation modal */}
      <Modal
        key={"confirmationModal"}
        isOpen={isOpenConfirmation}
        onClose={onCloseConfirmation}
        size={"md"}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius={16}>
          <ModalBody>
            <Box textAlign={"center"} p={4}>
              <Text fontWeight={600} fontSize={16}>
                Confirmation
              </Text>
              <Text fontWeight={400} fontSize={14} color={"#747A8B"}>
                Tick the box below to give your consent.
              </Text>
              <Checkbox
                my={4}
                onChange={(e) => setConfirmation(e.target.checked)}
              >
                I give my consent to share jorner's profile
              </Checkbox>

              <Button
                mt={8}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#fff"
                borderRadius={64}
                background={"primary"}
                isDisabled={!confirmation}
                onClick={onCloseConfirmation}
              >
                Share
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* delete modal */}
      <CustomModal
        onClose={onCloseDelete}
        onDone={onCloseDelete}
        isOpen={isOpenDelete}
        body={
          <Box>
            <Center>
              <Image src={deleteIcon} />
            </Center>
            <Text textAlign={"center"} fontSize={20} fontWeight={600}>
              Delete?
            </Text>
            <Text textAlign={"center"} fontSize={16} fontWeight={400}>
              The selected care plan will be deleted
            </Text>
            <Button
              mt={10}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={onCloseDelete}
            >
              Cancel
            </Button>
            <Button
              mt={1}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="secondary"
              borderRadius={64}
              border={"1px solid #EBECEF"}
              background={"white"}
              onClick={() => handleDeleteCarePlan(selectedCarePlan?.id)}
            >
              {isDeleting ? (
                <Spinner size={"sm"} color="#747A8B" />
              ) : (
                "Yes delete"
              )}
            </Button>
          </Box>
        }
      />
    </Box>
  );
}
